import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class PrivacyService {
  // POST request to fetch Privacy policy page section wise
  async getPrivacySection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'PrivacyPolicy',
      Section: payload.sectionName,
    });
    return response;
  }
}

const privacyService = new PrivacyService();
export default privacyService;
