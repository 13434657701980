import React, { useEffect } from 'react';
import Header from '../../components/TermsOfUse/Header/Header';
import Body from '../../components/TermsOfUse/Body/Body';
import { useDispatch, useSelector } from 'react-redux';
import { getTermsOfUseSection } from '../../actions/termsOfUse';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

const TermsOfUse = () => {
  const dispatch = useDispatch();
  const { SectionData } = useSelector((state) => state.termsOfUse);
  let section1 = '';

  if (SectionData) {
    section1 = SectionData[1][0];
  }

  useEffect(() => {
    dispatch(getTermsOfUseSection({ sectionName: '' }));
  }, []);

  return (
    <>
      <Helmet>{SectionData && parse(SectionData[0][0]?.MetaHeaderTags)}</Helmet>
      <Header section1={section1} />
      <Body section1={section1} />
    </>
  );
};

export default TermsOfUse;
