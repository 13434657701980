import { ServicesActionTypes } from '../store/slices/servicesSlice';

/**
 * To get services page data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getServicesSection = (payload) => ({
  type: ServicesActionTypes.FETCH_SERVICES,
  payload,
});
