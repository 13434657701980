import { createSlice } from '@reduxjs/toolkit';

// Initial state for Privacy slice
const initialState = {
  section1: {},
};

const blogSlice = createSlice({
  name: 'blogsPage',
  initialState,
  reducers: {
    setBlogPageSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const blogPageAction = blogSlice.actions;
export const blogPageReducer = blogSlice.reducer;

export const BlogActionTypes = {
  FETCH_BLOG_PAGE_DATA: 'blogPageData/Fetch',
};
