import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class ProjectsService {
  // POST request to fetch Projects page section wise
  async getProjectsSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'Projects',
      Section: payload.sectionName,
    });
    return response;
  }
}

const projectsService = new ProjectsService();
export default projectsService;
