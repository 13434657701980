import React from 'react';
import getDescriptionData from './DescriptionConfig';
import parse from 'html-react-parser';
import useDescriptionData from './DescriptionConfig';

const Description = ({ SectionData }) => {
  let section2 = '';
  let description1 = '';
  let description2 = '';

  const benefits = useDescriptionData();

  if (SectionData) {
    section2 = SectionData[2];
    description1 = parse(section2[0].CareerDescription1_Section2);
    description2 = parse(section2[0].CareerDescription2_Section2);
  }

  return (
    <div className='GlobalContainerNonBg'>
      <div className='container zIndex2'>
        <div className='row align-items-center'>
          <div className='col-md-12'>
            <div className='CareersPara' data-aos='fade-up'>
              <p>{description1}</p>
            </div>
            {/* <div className='CareersPara' data-aos='fade-up'>
              <h2>Welcome to our career page!</h2>
              <p>
                At OutMazed<sup>&reg;</sup> Design we believe that great design
                is not just about aesthetics, but also about practicality and
                livability. Our team is passionate about creating personalized
                spaces that are both beautiful and functional, and we are always
                on the lookout for talented individuals who share our vision and
                passion for design.
              </p>
              <p>
                If you are looking for an exciting and rewarding career in the
                interior design industry, here are some reasons why you should
                consider joining our team:
              </p>
            </div> */}
          </div>

          <div className='col-md-12'>
            {benefits.map(
              (benefit, index) =>
                (index % 2 === 0 && (
                  <div
                    className='CareersPoint'
                    key={index}
                    data-aos='fade-right'
                  >
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='CareersPointHead bdrRdarkLight pl-20'>
                          <h3>{benefit.heading}</h3>
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className='CareersPointPara pr-20'>
                          {benefit.detail}
                        </div>
                      </div>
                    </div>
                  </div>
                )) || (
                  <div
                    className='CareersPoint'
                    data-aos='fade-left'
                    key={index}
                  >
                    <div className='row'>
                      <div className='col-md-8 order-2 order-md-1'>
                        <div className='CareersPointPara pl-20'>
                          {benefit.detail}
                        </div>
                      </div>
                      <div className='col-md-4 order-1 order-md-2'>
                        <div className='CareersPointHead bdrLdarkLight pr-20 text-end'>
                          <h3 className='justify-content-end'>
                            {benefit.heading}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          <div className='col-md-12' data-aos='fade-up'>
            <div className='CareersPara'>
              <p>{description2}</p>
            </div>
            {/* <div className='CareersPara'>
              <p>
                We offer our team members continuous training and opportunities
                for development to enable them to progress and excel in their
                careers within our company.
              </p>
              <p>
                If you are passionate about interior design and want to work
                with a dynamic and inventive team of experts, we encourage you
                to apply for a career with us by sending an email to
                <a href='mailto:info@outmazeddesign.com'>
                  info@outmazeddesign.com
                </a>
                today!
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
