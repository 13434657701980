import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class TermsOfUseService {
  // POST request to fetch TermsOfUse policy page section wise
  async getTermsOfUseSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'TermsOfUse',
      Section: payload.sectionName,
    });
    return response;
  }
}

const termsOfUseService = new TermsOfUseService();
export default termsOfUseService;
