import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class SiteInfoService {
  // POST request to fetch site info section wise
  async getSiteInfoSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'SiteInfo',
      Section: payload.sectionName,
    });
    return response;
  }
}

const siteInfoService = new SiteInfoService();
export default siteInfoService;
