import { PAGE_URL } from './appConfig';
import OGLogo from '../assets/Outmazedlogo.png';

export const getPage = (url) => {
  return Object?.values(PAGE_URL)?.indexOf(url) !== -1 ? url : '';
};

export const META_INFO = {
  [PAGE_URL.HOMEPAGE]: {
    title:
      'Outmazed Interiors Design | Expert Interior Design & Renovation Services',
    keyword:
      'Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance',
    description:
      'Welcome to Outmazed Interiors Design! Discover our interior design, renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance services. Let us turn your vision into reality.',
    canonical: 'https://www.outmazeddesign.com/',
    author: 'Outmazed Design',
    pagetopic:
      'Interior Design, Furnishings, Project Management - Outmazed Design',
    Copyright: 'Outmazed Design',
    topic: 'Interior Design, Furnishings, Project Management - Outmazed Design',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle:
      'Outmazed Interiors Design | Transforming Spaces, Inspiring Lives ',
    fbogdesc:
      'Welcome to Outmazed Interiors Design! Explore our comprehensive range of interior design services including renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance. Let us transform your space into your dream home.',
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle:
      'Outmazed Interiors Design | Transforming Spaces, Inspiring Lives',
    twogdesc:
      'Welcome to Outmazed Interiors Design! Explore our comprehensive range of interior design services including renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance. Let us transform your space into your dream home.',
    dcogcreator: 'Outmazed Interiors Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle: 'Outmazed Interiors Design - Home',
    dcogdescription:
      'Welcome to Outmazed Interiors Design! Explore our comprehensive range of interior design services including renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance. Let us transform your space into your dream home.',
    dcogkeywords:
      'Outmazed Interiors Design, interior design, renovation, fit-outs & joinery, outdoor, furnishings, project management, maintenance',
    dcogsubject:
      'Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance',
    insogtitle:
      'Outmazed Interiors Design - Transforming Spaces, Inspiring Lives',
    insogdescription:
      'Welcome to Outmazed Interiors Design! Explore our comprehensive range of interior design services including renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance. Let us transform your space into your dream home.',
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.ABOUT]: {
    title:
      'About Outmazed Interiors - Crafting Unique Spaces with Expert Design',
    keyword:
      'Interior design, Outmazed Interiors, design philosophy, craftsmanship, creative solutions',
    description:
      'Get to know the heart and soul of Outmazed Design on our About Us page. Dive into our creative journey, meet our passionate team, and explore the values that drive our commitment to innovative interior solutions',
    canonical: 'https://www.outmazeddesign.com/about-outmazed-interiors',
    author: 'Outmazed Design',
    pagetopic:
      'Interior Design, Furnishings, Project Management - Outmazed Design',
    Copyright: 'Outmazed Design',
    topic: 'Interior Design, Furnishings, Project Management - Outmazed Design',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle: 'Outmazed Design: Interior, Furnishings, Project Management',
    fbogdesc:
      'Explore Outmazed Interiors, where passion and design excellence converge. Join us on the journey to transform both residential and commercial spaces into distinctive, inspiring environments. #OutmazedInteriors.',
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle: 'Outmazed Design: Interior, Furnishings, Project Management',
    twogdesc:
      'Crafting personalized and bespoke interiors that reflect your unique style. Join us in transforming spaces into reflections of your personality and style. #OutmazedInteriors.',
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle: '"Outmazed Interiors - Crafting Unique and Personalized Spaces ',
    dcogdescription:
      'Discover the world of Outmazed Interiors, where passion and creativity converge to craft personalized and timeless spaces for both residential and commercial environments. Explore our portfolio and join us in transforming your vision into reality. #OutmazedInteriors',
    dcogkeywords:
      'Outmazed Interiors, interior design, bespoke decor, personalized spaces, home decor, commercial interiors, design excellence',
    dcogsubject:
      'Interior design, Bespoke decor, Personalized spaces, Home and commercial interiors, Design philosophy',
    insogtitle: 'Outmazed Interiors - Crafting Unique and Personalized Spaces',
    insogdescription:
      'Discover the world of Outmazed Interiors, where passion and creativity converge to craft personalized and timeless spaces for both residential and commercial environments. Explore our portfolio and join us in transforming your vision into reality. #OutmazedInteriors',
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.SERVICE]: {
    title:
      'Outmazed Interiors Design Services - Elevating Spaces with Expertise',
    keyword:
      'Interior design, Renovation, Fit-outs & Joinery, Outdoor design, Furnishings, Project Management, Maintenance, Outmazed Interiors services',
    description:
      'Explore comprehensive services at Outmazed Interiors - Interior Design, Renovation, Fit-outs & Joinery, Outdoor design, Furnishings, Project Management, Maintenance. Elevate your spaces with our expertise. Transform your project with Outmazed - where design meets excellence',
    canonical: 'https://www.outmazeddesign.com/interiors-design-services',
    author: 'Outmazed Design',
    pagetopic: 'Interior Design and Home Styling Services',
    Copyright: 'Outmazed Design',
    topic:
      'Interior design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance, Outmazed Interiors services',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle:
      'Outmazed Interiors - Elevating Spaces with Expert Design Services',
    fbogdesc:
      'Explore our comprehensive suite of design services at Outmazed Interiors, including Interior Design, Renovation, Fit-outs & Joinery, Outdoor design, Furnishings, Project Management, and Maintenance. Transform your spaces with our unrivaled expertise. Elevate your home or commercial project with Outmazed Interiors - where design meets excellence.',
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle:
      'Outmazed Interiors - Elevating Spaces with Expert Design Services',
    twogdesc:
      'Explore our comprehensive suite of design services at Outmazed Interiors, including Interior Design, Renovation, Fit-outs & Joinery, Outdoor design, Furnishings, Project Management, and Maintenance. Transform your spaces with our unrivaled expertise. Elevate your home or commercial project with Outmazed Interiors - where design meets excellence.',
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle:
      'Outmazed Interiors - Elevating Spaces with Expert Design Services ',
    dcogdescription:
      'Explore our comprehensive suite of design services at Outmazed Interiors, including Interior Design, Renovation, Fit-outs & Joinery, Outdoor design, Furnishings, Project Management, and Maintenance. Transform your spaces with our unrivaled expertise. Elevate your home or commercial project with Outmazed Interiors - where design meets excellence.',
    dcogkeywords:
      'Outmazed Interiors, Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance',
    dcogsubject:
      'Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance, Outmazed Interiors services',
    insogtitle:
      'Outmazed Interiors - Elevating Spaces with Expert Design Services',
    insogdescription:
      'Explore our comprehensive suite of design services at Outmazed Interiors, including Interior Design, Renovation, Fit-outs & Joinery, Outdoor design, Furnishings, Project Management, and Maintenance. Transform your spaces with our unrivaled expertise. Elevate your home or commercial project with Outmazed Interiors - where design meets excellence.',
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.PROJECTS]: {
    title:
      'Outmazed Interiors Design Projects - Showcasing Creative Excellence',
    keyword:
      'Interior design projects, Outmazed Interiors, residential designs, commercial spaces, bespoke projects, design portfolio',
    description: `Explore Outmazed Interiors' diverse design projects, showcasing creative excellence in residential and commercial spaces. Elevate your space with our exceptional design journey. #OutmazedProjects`,
    canonical: 'https://www.outmazeddesign.com/interior-design-projects',
    author: 'Outmazed Design',
    pagetopic: 'Interior Design Projects, Creative Excellence, Bespoke Designs',
    Copyright: 'Outmazed Design',
    topic: 'interior Design Projects, Creative Excellence, Bespoke Designs',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle:
      'Outmazed Interiors Design Projects - Creative Excellence Unveiled',
    fbogdesc: `Explore Outmazed Interiors' diverse design projects, showcasing creative excellence in residential and commercial spaces. Elevate your space with our exceptional design journey. Discover timeless environments that reflect individuality. #OutmazedProjects`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle:
      'Outmazed Interiors Design Projects - Creative Excellence Unveiled',
    twogdesc: `Explore Outmazed Interiors' diverse design projects, showcasing creative excellence in residential and commercial spaces. Elevate your space with our exceptional design journey. Discover timeless environments that reflect individuality. #OutmazedProjects`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle:
      'Outmazed Interiors Design Projects - Creative Excellence Unveiled',
    dcogdescription: `Explore Outmazed Interiors' diverse design projects, showcasing creative excellence in residential and commercial spaces. Elevate your space with our exceptional design journey. Discover timeless environments that reflect individuality. #OutmazedProjects`,
    dcogkeywords:
      'Outmazed Interiors, Interior Design Projects, Creative Excellence, Residential Design, Commercial Spaces, Bespoke Projects, Design Portfolio',
    dcogsubject:
      'Interior Design Projects, Creative Excellence, Residential and Commercial Spaces, Bespoke Designs, Design Portfolio, Outmazed Interiors',
    insogtitle:
      'Outmazed Interiors Design Projects - Creative Excellence Unveiled',
    insogdescription: `Explore Outmazed Interiors' diverse design projects, showcasing creative excellence in residential and commercial spaces. Elevate your space with our exceptional design journey. Discover timeless environments that reflect individuality. #OutmazedProjects`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.PROJECT_DETAILS]: {},
  [PAGE_URL.THEME]: {
    title: 'Interior Design Themes | Outmazed Interiors Design',
    keyword:
      'Interior Design Themes, Home Decor, Room Design, Interior Styling',
    description: `Explore a range of inspiring interior design themes curated by Outmazed Interiors Design. Discover ideas to elevate your space with our expertise in home decor, room design, and interior styling.`,
    canonical: 'https://www.outmazeddesign.com/interior-design-themes',
    author: 'Outmazed Design',
    pagetopic: 'Interior Design Themes',
    Copyright: 'Outmazed Design',
    topic: 'Interior Design Themes',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle: 'Interior Design Themes | Outmazed Interiors Design',
    fbogdesc: `Explore a variety of inspiring interior design themes curated by Outmazed Interiors Design. Discover ideas to transform your space into a reflection of your style and personality.`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle: 'Interior Design Themes | Outmazed Interiors Design',
    twogdesc: `Discover a variety of inspiring interior design themes curated by Outmazed Interiors Design. Explore ideas to transform your space into a reflection of your style and personality.`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle: 'Interior Design Themes | Outmazed Interiors Design',
    dcogdescription: `Explore a variety of inspiring interior design themes curated by Outmazed Interiors Design. Discover ideas to transform your space into a reflection of your style and personality.`,
    dcogkeywords:
      'Interior Design Themes, Home Decor, Room Design, Interior Styling, Inspiration',
    dcogsubject: 'Interior Design Themes ',
    insogtitle: 'Interior Design Themes | Outmazed Interiors Design',
    insogdescription: `Explore a variety of inspiring interior design themes curated by Outmazed Interiors Design. Discover ideas to transform your space into a reflection of your style and personality.`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.CAREERS]: {
    title:
      'Join Outmazed Interiors Design Team - Careers in Creative Excellence',
    keyword:
      'Careers, Outmazed Interiors, Design Jobs, Interior Design Careers, Join Our Team, Creative Opportunities',
    description: `Explore exciting career opportunities at Outmazed Interiors Design. Join our passionate team shaping the future of interior design. Discover fulfilling roles, creative challenges, and a culture of innovation. Elevate your career with Outmazed - where design meets excellence`,
    canonical: 'https://www.outmazeddesign.com/careers',
    author: 'Outmazed Design',
    pagetopic:
      'Interior Design Careers, Creative Opportunities, Join Our Team, Career Development',
    Copyright: 'Outmazed Design',
    topic:
      'Interior Design Careers, Creative Opportunities, Join Our Team, Career Development',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle:
      'Join Outmazed Interiors Design Team - Careers in Creative Excellence',
    fbogdesc: `Explore exciting career opportunities at Outmazed Interiors Design. Join our passionate team shaping the future of interior design. Discover fulfilling roles, creative challenges, and a culture of innovation. Elevate your career with Outmazed - where design meets excellence.`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle:
      'Join Outmazed Interiors Design Team - Careers in Creative Excellence',
    twogdesc: `Explore Outmazed Interiors' diverse design projects, showcasing creative excellence in residential and commercial spaces. Elevate your space with our exceptional design journey. Discover timeless environments that reflect individuality. #OutmazedProjects`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle:
      'Join Outmazed Interiors Design Team - Careers in Creative Excellence',
    dcogdescription: `Explore exciting career opportunities at Outmazed Interiors Design. Join our passionate team shaping the future of interior design. Discover fulfilling roles, creative challenges, and a culture of innovation. Elevate your career with Outmazed - where design meets excellence.`,
    dcogkeywords:
      'Careers, Interior Design, Creative Opportunities, Join Our Team, Design Careers, Outmazed Interiors',
    dcogsubject:
      'Careers, Interior Design, Creative Opportunities, Job Openings, Design Team, Outmazed Interiors',
    insogtitle:
      'Join Outmazed Interiors Design Team - Careers in Creative Excellence',
    insogdescription: `Explore exciting career opportunities at Outmazed Interiors Design. Join our passionate team shaping the future of interior design. Discover fulfilling roles, creative challenges, and a culture of innovation. Elevate your career with Outmazed - where design meets excellence.`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.CONTACT_US]: {
    title:
      'Get in Touch with Outmazed Interiors - Contact Us for Design Inquiries',
    keyword:
      'Contact Us, Outmazed Interiors, Design Consultation, Interior Design, Collaboration, Inquiry, Contact Information.',
    description: `Reach out to Outmazed Interiors for design inquiries and collaboration opportunities. Our team is ready to assist with your interior design needs. Contact us today for a personalized consultation and elevate your space with Outmazed's creative expertise`,
    canonical: 'https://www.outmazeddesign.com/contact-us',
    author: 'Outmazed Design',
    pagetopic:
      'Design Inquiries, Interior Design Collaboration, Contact Information',
    Copyright: 'Outmazed Design',
    topic:
      'Design Inquiries, Interior Design Collaboration, Contact Information',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle:
      'Contact Outmazed Interiors - Design Inquiries and Collaboration Opportunities ',
    fbogdesc: `Reach out to Outmazed Interiors for design inquiries and collaboration opportunities. Our team is ready to assist with your interior design needs. Contact us today for a personalized consultation and elevate your space with Outmazed's creative expertise.`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle:
      'Contact Outmazed Interiors - Design Inquiries and Collaboration Opportunities',
    twogdesc: `Reach out to Outmazed Interiors for design inquiries and collaboration opportunities. Our team is ready to assist with your interior design needs. Contact us today for a personalized consultation and elevate your space with Outmazed's creative expertise.`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle:
      'Contact Outmazed Interiors - Design Inquiries and Collaboration Opportunities',
    dcogdescription: `Reach out to Outmazed Interiors for design inquiries and collaboration opportunities. Our team is ready to assist with your interior design needs. Contact us today for a personalized consultation and elevate your space with Outmazed's creative expertise.`,
    dcogkeywords:
      'Contact Us, Design Inquiries, Collaboration Opportunities, Interior Design, Outmazed Interiors',
    dcogsubject:
      'Contact Us, Design Inquiries, Collaboration Opportunities, Interior Design, Outmazed Interiors',
    insogtitle:
      'Contact Outmazed Interiors - Design Inquiries and Collaboration Opportunities',
    insogdescription: `Reach out to Outmazed Interiors for design inquiries and collaboration opportunities. Our team is ready to assist with your interior design needs. Contact us today for a personalized consultation and elevate your space with Outmazed's creative expertise.`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.SERVICE_INTERIOR]: {
    title: 'Service Interior',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.SERVICE_FURNISH]: {
    title: 'Service Furnish',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.SERVICE_FIT_OUT]: {
    title: 'Service Fits',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.SERVICE_PROJECT_MANAGE]: {
    title: 'Service Project',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.SERVICE_OUTDOOR]: {
    title: 'Service Outdoor',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.SERVICE_RENOVATION]: {
    title: 'Service Renovation',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.PRIVACY]: {
    title:
      'Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance, Privacy Policy',
    keyword:
      'Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance, Privacy Policy',
    description: `Discover how Outmazed Interiors Design prioritizes your privacy and safeguards your personal information. Learn about our commitment to data security and confidentiality while providing interior design, renovation, fit-outs & joinery, outdoor, furnishings, project management, and maintenance services.`,
    canonical: 'https://www.outmazeddesign.com/privacy-policy',
    author: 'Outmazed Design',
    pagetopic: 'Privacy Policy for Outmazed Interiors Design',
    Copyright: 'Outmazed Design',
    topic: 'Privacy Policy for Outmazed Interiors Design',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle: 'Privacy Policy | Outmazed Interiors Design',
    fbogdesc: `Learn about how Outmazed Interiors Design prioritizes your privacy and safeguards your personal information. Discover our commitment to data security while providing interior design, renovation, fit-outs & joinery, outdoor, furnishings, project management, and maintenance services.`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle: 'Privacy Policy | Outmazed Interiors Design',
    twogdesc: `Learn about how Outmazed Interiors Design prioritizes your privacy and safeguards your personal information. Discover our commitment to data security while providing interior design, renovation, fit-outs & joinery, outdoor, furnishings, project management, and maintenance services.`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle: 'Privacy Policy | Outmazed Interiors Design',
    dcogdescription: `Explore Outmazed Interiors Design's privacy policy, ensuring the security and confidentiality of your personal information. Learn about our commitment to data protection while offering interior design, renovation, fit-outs & joinery, outdoor, furnishings, project management, and maintenance services.`,
    dcogkeywords:
      'Privacy Policy, Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance, Data Security, Confidentiality',
    dcogsubject:
      'Privacy Policy, Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance, Data Security, Confidentiality',
    insogtitle: 'Privacy Policy | Outmazed Interiors Design',
    insogdescription: ` Learn about how Outmazed Interiors Design prioritizes your privacy and safeguards your personal information. Discover our commitment to data security while providing interior design, renovation, fit-outs & joinery, outdoor, furnishings, project management, and maintenance services.`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.TERMS_OF_USE]: {
    title:
      'Terms and Conditions | Outmazed Interiors Design - Guidelines & Policies',
    keyword:
      'Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance, Terms and Conditions',
    description: `Discover Outmazed Interiors Design's concise terms and conditions for interior design, renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance services. Understand our policies for a seamless experience.`,
    canonical: 'https://www.outmazeddesign.com/terms-and-conditions',
    author: 'Outmazed Design',
    pagetopic: 'Terms and Conditions for Interior Design Services ',
    Copyright: 'Outmazed Design',
    topic: 'Terms and Conditions for Interior Design Services ',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle: 'Terms and Conditions | Outmazed Interiors Design',
    fbogdesc: `Explore Outmazed Interiors Design's terms and conditions, outlining guidelines for interior design, renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance services. Understand our policies for a seamless and satisfactory experience.`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle: 'Terms and Conditions | Outmazed Interiors Design',
    twogdesc: `Explore Outmazed Interiors Design's terms and conditions, outlining guidelines for interior design, renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance services. Understand our policies for a seamless and satisfactory experience.`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle: 'Terms and Conditions | Outmazed Interiors Design',
    dcogdescription: `Explore Outmazed Interiors Design's terms and conditions, outlining guidelines for interior design, renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance services. Understand our policies for a seamless and satisfactory experience.`,
    dcogkeywords:
      'Terms and Conditions, Interior Design, Renovation, Fit-outs & Joinery, Outdoor, Furnishings, Project Management, Maintenance',
    dcogsubject: 'Terms and Conditions, Interior Design Services',
    insogtitle: 'Terms and Conditions | Outmazed Interiors Design',
    insogdescription: `Explore Outmazed Interiors Design's terms and conditions, outlining guidelines for interior design, renovation, fit-outs & joinery, outdoor furnishings, project management, and maintenance services. Understand our policies for a seamless and satisfactory experience.`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.BLOGS]: {
    title:
      'Explore Inspiring Design Insights | Outmazed Interiors Design Blogs',
    keyword:
      'Interior Design Blogs, Design Inspiration, Outmazed Interiors, Creative Insights, Home Decor Trends, Architectural Ideas.',
    description: `Dive into the world of design with Outmazed Interiors. Discover our curated blogs offering insights into the latest interior design trends, creative inspirations, and expert tips. Transform your space with the knowledge shared by Outmazed's design enthusiasts.`,
    canonical: 'https://www.outmazeddesign.com/blogs',
    author: 'Outmazed Design',
    pagetopic:
      'Interior Design Blogs, Design Inspiration, Creative Insights, Home Decor Trends',
    Copyright: 'Outmazed Design',
    topic:
      'Interior Design Blogs, Design Inspiration, Creative Insights, Home Decor Trends',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle:
      'Explore Inspiring Design Insights | Outmazed Interiors Design Blogs',
    fbogdesc: `Dive into the world of design with Outmazed Interiors. Discover our curated blogs offering insights into the latest interior design trends, creative inspirations, and expert tips. Transform your space with the knowledge shared by Outmazed's design enthusiasts.`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle:
      'Explore Inspiring Design Insights | Outmazed Interiors Design Blogs',
    twogdesc: `Dive into the world of design with Outmazed Interiors. Discover our curated blogs offering insights into the latest interior design trends, creative inspirations, and expert tips. Transform your space with the knowledge shared by Outmazed's design enthusiasts.`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle:
      'Explore Inspiring Design Insights | Outmazed Interiors Design Blogs',
    dcogdescription: `Dive into the world of design with Outmazed Interiors. Discover our curated blogs offering insights into the latest interior design trends, creative inspirations, and expert tips. Transform your space with the knowledge shared by Outmazed's design enthusiasts.`,
    dcogkeywords:
      'Interior Design Blogs, Design Inspiration, Creative Insights, Home Decor Trends, Outmazed Interiors',
    dcogsubject:
      'Interior Design Blogs, Design Inspiration, Creative Insights, Home Decor Trends, Outmazed Interiors',
    insogtitle:
      'Explore Inspiring Design Insights - Outmazed Interiors Design Blogs',
    insogdescription: `Dive into the world of design with Outmazed Interiors. Discover our curated blogs offering insights into the latest interior design trends, creative inspirations, and expert tips. Transform your space with the knowledge shared by Outmazed's design enthusiasts.`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.FAQ]: {
    title:
      'Frequently Asked Questions - Outmazed Interiors Design Services Explained',
    keyword:
      'Interior Design FAQ, Renovation Queries, Fit-Outs and Joinery Questions, Outdoor Design FAQs, Furnishings Inquiries, Project Management Details, Maintenance Services FAQs.',
    description: `Find answers to common queries about Outmazed Interiors Design services. Explore our FAQs for insights into Interior Design, Renovation, Fit-out & Joinery, Outdoor Design, Furnishings, Project Management, and Maintenance. Discover the details you need to enhance your space with Outmazed's expertise.`,
    canonical: 'https://www.outmazeddesign.com/interior-design-faq',
    author: 'Outmazed Design',
    pagetopic:
      'Interior Design FAQs, Renovation Queries, Fit-Outs and Joinery Questions, Outdoor Design FAQs, Furnishings Inquiries, Project Management Details, Maintenance Services FAQs',
    Copyright: 'Outmazed Design',
    topic:
      'Interior Design FAQs, Renovation Queries, Fit-Outs and Joinery Questions, Outdoor Design FAQs, Furnishings Inquiries, Project Management Details, Maintenance Services FAQs',
    language: 'english',
    property: 'Outmazed Design',
    owner: 'https://www.outmazeddesign.com/',
    rating: 'General',
    googlebot: 'index, follow',
    yahooseeker: 'index, follow',
    msnbot: 'index, follow',
    replyto: 'info@outmazeddesign.com',
    allowsearch: 'yes',
    revisitafter: 'daily',
    distribution: 'global',
    expires: 'never',
    HandheldFriendly: 'true',
    revisitafter: 'everyday',
    robots: 'index, follow',
    YahooSeeker: 'index, follow',
    SearchEngines:
      'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
    fbogtype: 'website',
    fbogsitename: 'Outmazed Design',
    fbogurl: 'https://www.facebook.com/OutMazedDesign',
    fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    fbogtitle:
      'Frequently Asked Questions - Outmazed Interiors Design Services Explained',
    fbogdesc: `Find answers to common queries about Outmazed Interiors Design services. Explore our FAQs for insights into Interior Design, Renovation, Fit-out & Joinery, Outdoor Design, Furnishings, Project Management, and Maintenance. Discover the details you need to enhance your space with Outmazed's expertise.`,
    twogcard: 'summary_large_image',
    twogsitename: '@OutMazedDesign',
    twogcreator: '@OutMazedDesign',
    twogurl: 'https://twitter.com/OutMazedDesign',
    twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
    twoglogourlalt: 'Outmazed Design Logo',
    twogtitle:
      'Frequently Asked Questions - Outmazed Interiors Design Services Explained ',
    twogdesc: `Find answers to common queries about Outmazed Interiors Design services. Explore our FAQs for insights into Interior Design, Renovation, Fit-Outs & Joinery, Outdoor Design, Furnishings, Project Management, and Maintenance. Discover the details you need to enhance your space with Outmazed's expertise.`,
    dcogcreator: 'Outmazed Design',
    dcoglanguage: 'en',
    dcogsource: 'https://www.outmazeddesign.com/',
    dcogtitle:
      'Frequently Asked Questions - Outmazed Interiors Design Services Explained',
    dcogdescription: `Find answers to common queries about Outmazed Interiors Design services. Explore our FAQs for insights into Interior Design, Renovation, Fit-Outs & Joinery, Outdoor Design, Furnishings, Project Management, and Maintenance. Discover the details you need to enhance your space with Outmazed's expertise.`,
    dcogkeywords:
      ' Interior Design FAQs, Renovation Queries, Fit-Outs and Joinery Questions, Outdoor Design FAQs, Furnishings Inquiries, Project Management Details, Maintenance Services FAQs',
    dcogsubject:
      ' Interior Design FAQs, Renovation Queries, Fit-Outs and Joinery Questions, Outdoor Design FAQs, Furnishings Inquiries, Project Management Details, Maintenance Services FAQs',
    insogtitle:
      'Frequently Asked Questions - Outmazed Interiors Design Services Explained',
    insogdescription: `Find answers to common queries about Outmazed Interiors Design services. Explore our FAQs for insights into Interior Design, Renovation, Fit-Outs & Joinery, Outdoor Design, Furnishings, Project Management, and Maintenance. Discover the details you need to enhance your space with Outmazed's expertise.`,
    insogimg: 'https://www.outmazeddesign.com/images/logo.png',
    insogurl: 'https://www.instagram.com/outmazeddesign/',
  },
  [PAGE_URL.BLOG_DETAILS]: {
    title: 'Blog Details',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.PROJECT_DETAILS]: {
    title: 'Project Details',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.THEME_MODERN]: {
    title: 'Theme Modern',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.THEME_CLASSICAL]: {
    title: 'Theme Classical',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.THEME_POST_MODERN]: {
    title: 'Theme Post Modern',
    keyword: '',
    description: 'Outmazed About us description',
  },
  [PAGE_URL.THEME_ABSTRACT]: {
    title: 'Theme Abstract',
    keyword: '',
    description: 'Outmazed About us description',
  },
};
