/**
 * To check current browser support session / local storage or not
 * @returns boolean - true, if support else false.
 */
export const isWebStorageSupported = () => {
  const sStorage = window.sessionStorage;
  const lStorage = window.localStorage;
  try {
    sStorage.setItem('test', 'test');
    sStorage.removeItem('test');
    lStorage.setItem('test', 'test');
    lStorage.removeItem('test');
    return true;
  } catch (e) {
    /* istanbul ignore next */
    return false;
  }
};
