import { useSelector } from 'react-redux';

const usePositionData = () => {
  return useSelector((state) => {
    const { SectionData } = state.careers;

    const getPositionKey = (position) => {
      return position && position.toLowerCase().replace(/\s+/g, '-');
    };

    let positions = [];
    if (SectionData) {
      positions = SectionData[3].map(
        ({
          id,
          JobPosition_Section3,
          JobLocation_Section3,
          JobDescription_Section3,
        }) => ({
          id,
          JobPosition_Section3: JobPosition_Section3.toUpperCase(),
          JobLocation_Section3,
          JobDescription_Section3,
          key: getPositionKey(JobPosition_Section3),
        })
      );
    }

    return positions;
  });
};

export default usePositionData;
