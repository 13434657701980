import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class BlogPageService {
  // POST request to fetch About Us page section wise
  async getBlogPageData(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'Blogs',
      Section: payload.sectionName,
    });
    return response;
  }
}

const blogPageDataService = new BlogPageService();
export default blogPageDataService;
