import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class ServicesService {
  // POST request to fetch Services page section wise
  async getServicesSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'Services',
      Section: payload.sectionName,
    });
    return response;
  }
}

const servicesService = new ServicesService();
export default servicesService;
