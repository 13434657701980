import React, { useRef, useState, lazy, Suspense, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, useParams, useMatch } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { META_INFO, getPage } from '../../config/metaInfo';
import useOnRouteChange from '../../hooks/useOnRouteChange';
import {
  StyledContainer,
  StyledContent,
  StyledLayoutWrapper,
} from '../../styles/GlobalStyle';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
// import ContactUs from '../ContactUs/ContactUs';
const ContactUs = lazy(() => import('../ContactUs/ContactUs'));
import { Link } from 'react-router-dom';
import { PAGE_URL } from '../../config/appConfig';
import {
  BsChatDotsFill,
  BsPhone,
  BsPlus,
  BsPlusLg,
  BsTelephone,
  BsTelephoneFill,
} from 'react-icons/bs';
import {
  getProjectDetails,
  useProjectDetails,
} from '../ProjectsPage/ProjectsConfig';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { getSiteInfoSection } from '../../actions/siteInfo';
import favicon from '../../assets/favicon.ico';
import whatsAppIcon from '../../assets/whatsapp.png';

const PAGE_SLICES = {
  [PAGE_URL.CAREERS]: 'careers',
  [PAGE_URL.PRIVACY]: 'privacy',
  [PAGE_URL.ABOUT]: 'aboutUs',
  [PAGE_URL.BLOGS]: 'blog',
  [PAGE_URL.CONTACT_US]: 'contactUs',
  [PAGE_URL.FAQ]: 'faq',
  [PAGE_URL.HOMEPAGE]: 'home',
  [PAGE_URL.TERMS_OF_USE]: 'termsOfUse',
  [PAGE_URL.THEME]: 'themePage',
  [PAGE_URL.SERVICE]: 'services',
  [PAGE_URL.PROJECTS]: 'projects',
};
import { useDispatch } from 'react-redux';
import { getProjectsSection } from '../../actions/projects';
import { isEmpty } from 'lodash';
import Favicon from 'react-favicon';
import { getServicesSection } from '../../actions/services';
import { getContactUsSection } from '../../actions/contactUs';
import { getThemePageSection } from '../../actions/themePage';

/**
 * Layout component is the root level component to host all routes/components
 * @param {object} props - props object
 * @param {string} props.id - unique identifier, if needed
 * @param {array} props.children - children components, if any
 *
 * @returns React.FC
 */
const Layout = ({ id, children }) => {
  const params = useParams();
  const match = useMatch(PAGE_URL.PROJECT_DETAILS);
  const location = useLocation();
  const locationRef = useRef();
  const [metaInfo, setMetaInfo] = useState({});
  const projects = useProjectDetails();
  const bodyOpenRef = useRef(null);
  const bodyCloseRef = useRef(null);
  const [metaHeadTags, setMetaHeadTags] = useState(null);
  const store = useSelector((state) => state);
  const { SectionData } = useSelector((state) => state.siteInfo);
  const [siteInfoMeta, setSiteInfoMeta] = useState(null);
  const siteBodyOpenRef = useRef(null);
  const siteBodyCloseRef = useRef(null);
  const contactUsRef = useRef(null);
  const footerRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServicesSection({ sectionName: '' }));
    dispatch(getProjectsSection({ sectionName: 'section2' }));
    dispatch(getContactUsSection({ sectionName: '' }));
    dispatch(getThemePageSection({ sectionName: '' }));
  }, []);

  const removeBodyTags = () => {
    if (bodyOpenRef.current) {
      bodyOpenRef.current.remove();
    }

    if (bodyCloseRef.current) {
      bodyCloseRef.current.remove();
    }
  };

  useEffect(() => {
    dispatch(getSiteInfoSection({ sectionName: '' }));
  }, []);

  useEffect(() => {
    if (SectionData) {
      const { MetaScriptTags_Closing, MetaScriptTags_Opening } =
        SectionData[0][0];
      // setSiteInfoMeta(parse(MetaHeaderTags));
      const siteBodyOpenDiv = document.createElement('div');
      const siteBodyCloseDiv = document.createElement('div');
      siteBodyOpenDiv.innerHTML = MetaScriptTags_Opening;
      siteBodyCloseDiv.innerHTML = MetaScriptTags_Closing;
      document.body.insertAdjacentElement('afterbegin', siteBodyOpenDiv);
      document.body.insertAdjacentElement('beforeend', siteBodyCloseDiv);
      siteBodyOpenRef.current = siteBodyOpenDiv;
      siteBodyCloseRef.current = siteBodyCloseDiv;
    }

    return () => {
      if (siteBodyOpenRef.current) {
        siteBodyOpenRef.current.remove();
      }

      if (siteBodyCloseRef.current) {
        siteBodyCloseRef.current.remove();
      }
    };
  }, [SectionData]);

  useEffect(() => {
    const pageRoute = location.pathname;
    let isMetaPresent = false;
    let metaData;
    if (
      pageRoute === PAGE_URL.PROJECTS &&
      !isEmpty(store[PAGE_SLICES[pageRoute]]?.metaSection)
    ) {
      isMetaPresent = true;
      metaData = store[PAGE_SLICES[pageRoute]]?.metaSection;
    } else if (Array.isArray(store[PAGE_SLICES[pageRoute]]?.SectionData)) {
      isMetaPresent = true;
      metaData = store[PAGE_SLICES[pageRoute]]?.SectionData[0][0];
    }

    if (isMetaPresent) {
      const bodyOpenDiv = document.createElement('div');
      const bodyCloseDiv = document.createElement('div');
      const { MetaHeaderTags, MetaScriptTags_Opening, MetaScriptTags_Closing } =
        metaData;
      // setMetaHeadTags(parse(MetaHeaderTags));
      bodyOpenDiv.innerHTML = MetaScriptTags_Opening;
      bodyCloseDiv.innerHTML = MetaScriptTags_Closing;
      document.body.insertAdjacentElement('afterbegin', bodyOpenDiv);
      document.body.insertAdjacentElement('beforeend', bodyCloseDiv);
      bodyOpenRef.current = bodyOpenDiv;
      bodyCloseRef.current = bodyCloseDiv;
    }

    return () => removeBodyTags();
  }, [store]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { id: 'contact-us-form', ref: contactUsRef },
        { id: 'footer', ref: footerRef },
      ];

      for (let section of sections) {
        const { id, ref } = section;
        const offsetTop = ref.current?.offsetTop;
        const offsetHeight = ref.current?.offsetHeight;
        const scrollPosition = window.scrollY + window.innerHeight / 2;

        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          window.history.replaceState(null, null, `#${id}`);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    setTimeout(() => {
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 1000);
  }, [SectionData]);

  return (
    <Suspense
      fallback={
        <div className='w-100 vh-100 d-flex justify-content-center align-items-center'>
          <Spinner animation='grow' variant='primary' />
        </div>
      }
    >
      <HelmetProvider>
        <Helmet>{siteInfoMeta}</Helmet>
        {/* <Helmet>{metaHeadTags}</Helmet> */}
        <Favicon
          url={(SectionData && SectionData[1][0]?.SiteFavicon) || favicon}
        />
        <StyledLayoutWrapper data-testid={`${id}-layout`}>
          <StyledContainer data-testid={`${id}-container`}>
            <Header />
            <StyledContent>{children}</StyledContent>
            {!location.pathname
              ?.split('/')
              ?.some(
                (segment) =>
                  segment === 'apply' ||
                  segment === 'get-quote' ||
                  segment === 'flip-book'
              ) && (
              <section id='contact-us-form' ref={contactUsRef}>
                <ContactUs />
              </section>
            )}
            <section id='footer' ref={footerRef}>
              <Footer />
            </section>
          </StyledContainer>
        </StyledLayoutWrapper>
        <div className='SitckyBtnSide'>
          <Link to={PAGE_URL.GET_QUOTE}>GET QUOTE</Link>
        </div>
        <div className='mobileSitckyBtn'>
          <div className='menu'>
            <input className='toggle' id='menu' type='checkbox' />
            <label className='style' htmlFor='menu'>
              <BsPlus />
            </label>

            <a
              className='tab'
              href={`tel://${SectionData && SectionData[1][0]?.SitePhoneNo}`}
            >
              <BsTelephoneFill />
            </a>
            <a
              className='tab'
              href={`mailto:${SectionData && SectionData[1][0]?.SiteEmail}`}
            >
              <BsChatDotsFill />
            </a>
            <Link to={PAGE_URL.GET_QUOTE} className='tab get'>
              Get Quote
            </Link>
          </div>
        </div>
        <div className='whatsAppBtnDiv'>
          <a
            className='whatsAppBtn'
            target='_blank'
            href='https://wa.me/97143399683'
          >
            <img
              src={whatsAppIcon}
              alt='whatsapp logo'
              style={{ width: 'inherit' }}
            />
          </a>
        </div>
        <div className='whatsAppBtnDivlg'>
          <a target='_blank' href='https://wa.me/97143399683'>
            <button className='whatsAppBtnlg'>
              <img
                src={whatsAppIcon}
                alt='whatsapp logo'
                style={{ width: '30px', margin: '10px' }}
              />
              <p>Start a chat</p>
            </button>
          </a>
        </div>
      </HelmetProvider>
    </Suspense>
  );
};

export default Layout;
