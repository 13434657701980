import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { LANGUAGES } from '../config/appConfig';
import { LanguageContext } from '../contexts/LanguageContext';
import useLocale from '../hooks/useLocale';

/**
 * LanguageProvider to synch selected language and provide language list for language switcher component.
 *
 * @namespace LanguageProvider
 * @param {React.ReactNode} children - children elements
 *
 * @returns - ReactElement
 */
export const LanguageProvider = ({ children }) => {
  /**
   * to get selected locale if available else get default locale from browser.
   */
  const [locale, setLocale] = useLocale();
  const { locale: lang, direction } = locale;

  const value = useMemo(() => {
    return {
      lang,
      direction,
      setLocale,
      LANGUAGES,
    };
  }, [lang]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
