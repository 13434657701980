import React, { useEffect } from 'react';
import parse from 'html-react-parser';

const Header = ({ SectionData }) => {
  let section1 = '';
  let title = '';
  let image = '';
  let imageAlt = '';
  let image2 = '';
  let image2Alt = '';

  if (SectionData) {
    section1 = SectionData[1][0];
    title = parse(section1.HeaderTitle_Section1);
    image = section1.HeaderImageURL_Section1;
    imageAlt = section1.HeaderImageAltTag;
    image2 = section1.MobileImageURL_Section1;
    image2Alt = section1.MobileImageAltTag;
  }

  return (
    <div className='container-fluid p-0 StickScroll'>
      <div className='inSideHeader'>
        <img src={image} alt={imageAlt} className='WebImg' />
        <img src={image2} alt={image2Alt} className='MobileImg' />
        <div className='inSideHeaderCaption' data-aos='fade-up'>
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
