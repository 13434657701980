import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { I18nContext } from '../contexts/I18nContext';
import { LanguageContext } from '../contexts/LanguageContext';

/**
 *
 * @param {string} lang - selected language
 * @returns Object - i18n messages object of given language
 *
 */
const loadMessages = async (lang) => {
  // TODO: need to optimize by using cache mechanism
  lang = lang.split(/[-_]/)[0];
  const message = (await import(`../lang/${lang}.json`)).default;
  return message;
};

/**
 * I18nProvider to provide language translation respective to the selected language.
 *
 * @namespace I18nProvider
 * @param {Object} props
 * @param {React.ReactNode} props.children - children elements
 * @returns - ReactElement
 */

const I18nProvider = ({ children }) => {
  /**
   * To store message of the selected language
   */
  const [messages, setMessages] = useState(null);

  /**
   * To get selected language (preference) from language context
   */
  const { lang } = useContext(LanguageContext);

  /**
   * To load language messages on language change
   */
  useEffect(() => {
    const loadI18nMessages = async () => {
      const message = await loadMessages(lang);
      setMessages(message);
    };
    loadI18nMessages();
  }, [lang]);

  const handleOnIntlError = (error) => {
    console.log(error);
  };

  return lang && messages ? (
    <I18nContext.Provider value={{ lang }}>
      <IntlProvider
        locale={lang}
        messages={messages}
        onError={handleOnIntlError}
      >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  ) : null;
};

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default I18nProvider;
