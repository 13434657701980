import careerImg from '../../../assets/careersHeader.jpg';
import careerImgMobile from '../../../assets/careersHeaderMobile.jpg';
import parse from 'html-react-parser';

const CareersPage = ({ SectionData }) => {
  let section1 = '';
  let title = '';
  let image = '';
  let imageAlt = '';
  let image2 = '';
  let image2Alt = '';

  if (SectionData) {
    section1 = SectionData[1][0];
    title = parse(section1.HeaderTitle_Section1);
    image = section1.HeaderBackgroundImageUrl_Section1;
    imageAlt = section1.HeaderBackgroundImageAltTag;
    image2 = section1.MobileImageURL_Section1;
    image2Alt = section1.MobileImageAltTag;
  }
  return (
    <div className='container-fluid p-0'>
      <div className='inSideHeader'>
        <img src={image} alt={imageAlt} className='WebImg' />
        {/* <img src={careerImg} alt='careerImg' className='WebImg' /> */}
        <img src={image2} alt={image2Alt} className='MobileImg' />
        {/* <img src={careerImgMobile} alt='careerImg' className='MobileImg' /> */}
        <div className='inSideHeaderCaption' data-aos='fade-right'>
          <h1>{title}</h1>
          {/* <h1>
            careers at outmazed<sup>&reg;</sup> design
          </h1> */}
        </div>
      </div>
    </div>
  );
};

export default CareersPage;
