import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class ContactUsService {
  // POST request to fetch ContactUs page section wise
  async getContactUsSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'ContactUs',
      Section: payload.sectionName,
    });
    return response;
  }
}

const contactUsService = new ContactUsService();
export default contactUsService;
