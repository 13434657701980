import { CareersActionTypes } from '../store/slices/careersSlice';

/**
 * To get careers page data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getCareersSection = (payload) => ({
  type: CareersActionTypes.FETCH_CAREERS,
  payload,
});
