import { useState, useEffect, useRef } from 'react';
import outmazedLogo from '../../assets/footerLogo.svg';
import { getHeaderData } from './HeaderConfig';
import { Link } from 'react-router-dom';
import { PAGE_URL } from '../../config/appConfig';
import { BsTelephoneFill, BsEnvelopeFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeClass, setActiveClass] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { SectionData } = useSelector((state) => state.siteInfo);

  const hendleToggle = () => {
    if (window.innerWidth < 768) {
      setIsNavOpen(!isNavOpen);
      document.body.classList.toggle('StopScroll', !isNavOpen);
    }
  };

  const handleButtonClick = () => {
    if (menuOpen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  };

  const handleDocumentClick = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('scroll', handleDocumentClick);
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('scroll', handleDocumentClick);
      document.removeEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('scroll', handleDocumentClick);
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [menuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 6) {
        setActiveClass('sticky');
      } else {
        setActiveClass('');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className={`navtop ${activeClass}`}>
        <div className='container-fluid'>
          <nav className={`navbar navbar-expand-lg navbar-dark`}>
            <Link className='navbar-brand d-lg-none' to={PAGE_URL.HOMEPAGE}>
              <img
                src={
                  (SectionData && SectionData[1][0]?.SiteHeaderLogo) ||
                  outmazedLogo
                }
                alt={SectionData ? SectionData[1][0]?.HeaderLogoAltTag : ''}
              />
            </Link>
            <button
              className={`navbar-toggler ${menuOpen ? '' : 'collapsed'}`}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarNav'
              aria-controls='navbarNav'
              aria-expanded={menuOpen}
              onClick={() => {
                handleButtonClick();
                hendleToggle();
              }}
              aria-label='Toggle navigation'
            >
              <span className='icon-bar top-bar'></span>
              <span className='icon-bar middle-bar'></span>
              <span className='icon-bar bottom-bar'></span>
            </button>
            <div
              className={`collapse navbar-collapse ${!menuOpen ? '' : 'show'}`}
              id='navbarNav'
              ref={menuRef}
            >
              <ul className='navbar-nav mx-auto'>
                {getHeaderData().map((navItem, index) => (
                  <li className='nav-item' key={index}>
                    <Link
                      className={`nav-link ${
                        navItem?.href === location.pathname
                          ? 'NavactiveSer'
                          : ''
                      }`}
                      aria-current='page'
                      to={navItem?.href}
                      onClick={() => {
                        handleButtonClick();
                        hendleToggle();
                      }}
                    >
                      {navItem?.label}
                    </Link>
                    {navItem?.image && (
                      <Link
                        className='navbar-brand d-none d-lg-block CenterLogo'
                        to={navItem?.href}
                      >
                        <img
                          src={
                            (SectionData &&
                              SectionData[1][0]?.SiteHeaderLogo) ||
                            outmazedLogo
                          }
                          alt={
                            SectionData
                              ? SectionData[1][0]?.HeaderLogoAltTag
                              : ''
                          }
                        />
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              <div className='MobileInfo'>
                {/* <Link to='tel://+97143399683'>
                  <BsTelephoneFill />
                  +971 4 339 9683
                </Link> */}
                <a
                  href={`tel://${
                    SectionData && SectionData[1][0]?.SitePhoneNo
                  }`}
                  onClick={() => {
                    handleButtonClick();
                    hendleToggle();
                  }}
                >
                  <BsTelephoneFill />
                  {SectionData && SectionData[1][0]?.SitePhoneNo}
                </a>
                {/* <Link to='mailto:info@outmazeddesign.com'>
                  <BsEnvelopeFill />
                  info@outmazeddesign.com
                </Link> */}
                <a
                  href={`mailto:${SectionData && SectionData[1][0]?.SiteEmail}`}
                  onClick={() => {
                    handleButtonClick();
                    hendleToggle();
                  }}
                >
                  <BsEnvelopeFill />
                  {SectionData && SectionData[1][0]?.SiteEmail}
                </a>
                <Link
                  to={PAGE_URL.GET_QUOTE}
                  className='MmenuGQbtn'
                  onClick={() => {
                    handleButtonClick();
                    hendleToggle();
                  }}
                >
                  GET QUOTE
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
