import { ProjectsActionTypes } from '../store/slices/projectsSslice';

/**
 * To get projects page data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getProjectsSection = (payload) => ({
  type: ProjectsActionTypes.FETCH_PROJECTS,
  payload,
});
