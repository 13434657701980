import { TermsOfUseActionTypes } from '../store/slices/termsOfUseSlice';

/**
 * To get termsOfUse page data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getTermsOfUseSection = (payload) => ({
  type: TermsOfUseActionTypes.FETCH_TERMS_OF_USE,
  payload,
});
