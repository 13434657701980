import { createSlice } from '@reduxjs/toolkit';

// Initial state for ContactUs slice
const initialState = {
  section1: {},
};

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    setContactUsSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const contactUsActions = contactUsSlice.actions;
export const contactUsReducer = contactUsSlice.reducer;

export const ContactUsActionTypes = {
  FETCH_CONTACT_US: 'ContactUs/Fetch',
};
