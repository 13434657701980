import React, { useEffect } from 'react';
import image1 from '../../../assets/Modern-Theme-1.jpg';
import image2 from '../../../assets/Modern-Theme-2.jpg';
import image3 from '../../../assets/Modern-Theme-3.jpg';
import image6 from '../../../assets/Classical-Theme-1.jpg';
import image4 from '../../../assets/Classical-Theme-2.jpg';
import image5 from '../../../assets/Classical-Theme-3.jpg';
import image7 from '../../../assets/Post-Modern-Theme-1.jpg';
import image8 from '../../../assets/Post-modern-Theme-2.jpg';
import image9 from '../../../assets/Post-Modern-Theme-3.jpg';
import image10 from '../../../assets/Abstract-Theme-1.jpg';
import image11 from '../../../assets/Abstract-Theme-2.jpg';
import image12 from '../../../assets/Abstract-Theme-3.jpg';
import { BsArrowRight } from 'react-icons/bs';
import { PAGE_URL } from '../../../config/appConfig';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getThemePageSection } from '../../../actions/themePage';

const Variety = ({ SectionData }) => {
  const location = useLocation();

  useEffect(() => {
    // Check if the fragment identifier is in the URL and scroll to it
    if (location.hash) {
      setTimeout(() => {
        document
          .getElementById(location.hash)
          ?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      // lifeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, SectionData]);
  return (
    <>
      {Array.isArray(SectionData) &&
        SectionData[2].map(
          (item, index) =>
            index === 0 && (
              <div
                className='GlobalContainerNonBg DarkLightBg pb-0'
                id='#title'
              >
                <div className='container zIndex2'>
                  <div className='row'>
                    <div className='col-md-12' data-aos='fade-up'>
                      <div className='GlobalHeading'>
                        <div className='titleBig'>
                          {item.SectionTitle_Section2}
                        </div>
                        <span>{item.SectionTitle_Section2}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      {Array.isArray(SectionData) &&
        SectionData[3].map((item, index) =>
          index % 2 === 0 ? (
            <div
              className='GlobalContainer'
              id={`#${item.ThemeTitle_Section2.toLowerCase().replace(
                /\s+/g,
                '_'
              )}`}
            >
              <div className='container zIndex2'>
                <div className='row justify-content-center'>
                  <div className='col-md-12 ThemeMobile' data-aos='fade-up'>
                    <div className='themeItem'>
                      <h4>{'0' + (index + 1)}</h4>
                      <span>
                        <h2>{item.ThemeTitle_Section2}</h2>
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4 col-6' data-aos='fade-up'>
                    <img
                      src={item.ThemeImageURL1_Section2}
                      className='w-100'
                      alt={item.ThemeImageURL1AltTag}
                    />
                  </div>
                  <div className='col-md-4 col-6' data-aos='fade-right'>
                    <div className='themeInfoCon'>
                      <div className='themeText'>
                        <div className='themeItem'>
                          <h4>{'0' + (index + 1)}</h4>
                          <span>
                            <h2>{item.ThemeTitle_Section2}</h2>
                          </span>
                        </div>
                        <Link
                          to={PAGE_URL.PROJECTS}
                          className='linkWhite  d-md-block d-none'
                        >
                          View All Projects <BsArrowRight />
                        </Link>
                      </div>
                      <div className='themeThumb'>
                        <img
                          src={item.ThemeImageURL2_Section2}
                          alt={item.ThemeImageURL2AltTag}
                        />
                        <img
                          src={item.ThemeImageURL3_Section2}
                          alt={item.ThemeImageURL3AltTag}
                        />
                      </div>
                    </div>
                  </div>
                  <Link
                    to={PAGE_URL.PROJECTS}
                    className='linkWhite d-md-none d-block'
                  >
                    View All Projects <BsArrowRight />
                  </Link>
                </div>
              </div>
              <div className='BgAnimationTheme'>
                <div className='span2'></div>
              </div>
            </div>
          ) : (
            <>
              <div
                className='GlobalContainer'
                id={`#${item.ThemeTitle_Section2.toLowerCase().replace(
                  /\s+/g,
                  '_'
                )}`}
              >
                <div className='container zIndex2'>
                  <div className='row justify-content-center'>
                    <div className='col-md-12 ThemeMobile' data-aos='fade-up'>
                      <div className='themeItem'>
                        <h4>{'0' + (index + 1)}</h4>
                        <span>
                          <h2>{item.ThemeTitle_Section2}</h2>
                        </span>
                      </div>
                    </div>
                    <div
                      className='col-md-4 col-6 order-md-2'
                      data-aos='fade-left'
                    >
                      <img
                        src={item.ThemeImageURL3_Section2}
                        className='w-100'
                        alt={item.ThemeImageURL1AltTag}
                      />
                    </div>
                    <div
                      className='col-md-4 col-6 order-md-1 '
                      data-aos='fade-up'
                    >
                      <div className='themeInfoCon'>
                        <div className='themeText'>
                          <div className='themeItem'>
                            <h4>{'0' + (index + 1)}</h4>
                            <span>
                              <h2>{item.ThemeTitle_Section2}</h2>
                            </span>
                          </div>
                          <Link
                            to={PAGE_URL.PROJECTS}
                            className='linkWhite  d-md-block d-none'
                          >
                            View All Projects <BsArrowRight />
                          </Link>
                        </div>
                        <div className='themeThumb'>
                          <img
                            src={item.ThemeImageURL1_Section2}
                            alt={item.ThemeImageURL2AltTag}
                          />
                          <img
                            src={item.ThemeImageURL2_Section2}
                            alt={item.ThemeImageURL3AltTag}
                          />
                        </div>
                      </div>
                    </div>
                    <Link
                      to={PAGE_URL.PROJECTS}
                      className='linkWhite d-md-none d-block'
                    >
                      View All Projects <BsArrowRight />
                    </Link>
                  </div>
                </div>
                <div className='BgAnimationTheme'>
                  <div className='span1'></div>
                </div>
              </div>
            </>
          )
        )}
    </>
  );
};

export default Variety;
