import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import homeService from '../services/HomeService';
import { HomeActionTypes, homeActions } from '../store/slices/homeSlice';

// saga handler to fetch Home sectionwise and update redux store as per the api payload
export const fetchHomeSection = function* (action) {
  try {
    const response = yield call(homeService.getHomeSection, action.payload);
    const responseData = yield get(response, 'data');
    yield put(
      homeActions.setHomeSection({
        sectionName: 'SectionData',
        data: responseData.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* homeSaga() {
  yield takeLatest(HomeActionTypes.FETCH_HOME, fetchHomeSection);
}
