import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class HomeService {
  // POST request to fetch Home page section wise
  async getHomeSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'Home',
      Section: payload.sectionName,
    });
    return response;
  }
}

const homeService = new HomeService();
export default homeService;
