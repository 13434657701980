import { createSlice } from '@reduxjs/toolkit';

// Initial state for Services slice
const initialState = {
  section1: {},
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServicesSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const servicesActions = servicesSlice.actions;
export const servicesReducer = servicesSlice.reducer;

export const ServicesActionTypes = {
  FETCH_SERVICES: 'Services/Fetch',
};
