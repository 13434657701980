import DoorStepLeftImg from '../../assets/Door_Step_1.jpg';
import DoorStepRightImg from '../../assets/Door_Step_2.jpg';
import AlphaLeftImg from '../../assets/Alpha_1.jpg';
import AlphaRightImg from '../../assets/Alpha_2.jpg';
import BoldLeftImg from '../../assets/Bold_1.jpg';
import BoldRightImg from '../../assets/Bold_2.jpg';
import MiracleLeftImg from '../../assets/Miracle_1.jpg';
import MiracleRightImg from '../../assets/Miracle_2.jpg';
import WonderLeftImg from '../../assets/wonder_1.jpg';
import WonderRightImg from '../../assets/Wonder_2.jpg';
import JewelLeftImg from '../../assets/Jewel_1.jpg';
import JewelRightImg from '../../assets/Jewel_2.jpg';
import DesignLeftImg from '../../assets/Design_1.jpg';
import DesignRightImg from '../../assets/Design_2.jpg';

import FeatureDoorStep from '../../assets/Feature_Door_step.jpg';
import GalleryDoorStep1 from '../../assets/Gallery_DoorStep1.jpg';
import GalleryDoorStep2 from '../../assets/Gallery_DoorStep2.jpg';
import GalleryDoorStep3 from '../../assets/Gallery_DoorStep3.jpg';

import FeatureAlpha from '../../assets/Feature_Alpha.jpg';
import GalleryAlpha1 from '../../assets/Gallery_Alpha1.jpg';
import GalleryAlpha2 from '../../assets/Gallery_Alpha2.jpg';
import GalleryAlpha3 from '../../assets/Gallery_Alpha3.jpg';

import FeatureBold from '../../assets/Feature_Bold.jpg';
import GalleryBold1 from '../../assets/Gallery_Bold1.jpg';
import GalleryBold2 from '../../assets/Gallery_Bold2.jpg';
import GalleryBold3 from '../../assets/Gallery_Bold3.jpg';

import FeatureMiracle from '../../assets/Feature_Miracle.jpg';
import GalleryMiracle1 from '../../assets/Gallery_Miracle1.jpg';
import GalleryMiracle2 from '../../assets/Gallery_Miracle2.jpg';
import GalleryMiracle3 from '../../assets/Gallery_Miracle3.jpg';

import FeatureWonder from '../../assets/Feature_Wonder.jpg';
import GalleryWonder1 from '../../assets/Gallery_Wonder1.jpg';
import GalleryWonder2 from '../../assets/Gallery_Wonder2.jpg';
import GalleryWonder3 from '../../assets/Gallery_Wonder3.jpg';

import FeatureJewel from '../../assets/Feature_Jewel.jpg';
import GalleryJewel1 from '../../assets/Gallery_Jewel1.jpg';
import GalleryJewel2 from '../../assets/Gallery_Jewel2.jpg';
import GalleryJewel3 from '../../assets/Gallery_Jewel3.jpg';

import FeatureDesign from '../../assets/Feature_Design.jpg';
import GalleryDesign1 from '../../assets/Gallery_Design1.jpg';
import GalleryDesign2 from '../../assets/Gallery_Design2.jpg';
import GalleryDesign3 from '../../assets/Gallery_Design3.jpg';

import vidPlay from '../../assets/DoorStepVideo_BG.jpg';
import alphaVideo from '../../assets/Alpha_Video_BG.jpg';
import boldVideo from '../../assets/Bold_Video_BG.jpg';
import designVideo from '../../assets/Design_Video_BG.jpg';
import jewelVideo from '../../assets/JewelVideo_BG.jpg';
import miracleVideo from '../../assets/MiracleVideo_BG.jpg';
import wonderVideo from '../../assets/WonderVideo_BG.jpg';

import projectDetailsDoorStep from '../../assets/ProjectDetail_Door-Step.jpg';
import projectDetailsAlpha from '../../assets/ProjectDetail_Alpha.jpg';
import projectDetailsBold from '../../assets/ProjectDetail_Bold.jpg';
import projectDetailsMiracle from '../../assets/ProjectDetail_Miracle.jpg';
import projectDetailsWonder from '../../assets/ProjectDetail_Wonder.jpg';
import projectDetailsJewel from '../../assets/ProjectDetail_Jewel.jpg';
import projectDetailsDesign from '../../assets/ProjectDetails_Design.jpg';
import projectDetailsDoorStepMobile from '../../assets/ProjectDetail_Door-StepMobile.jpg';
import projectDetailsAlphaMobile from '../../assets/ProjectDetail_AlphaMobile.jpg';
import projectDetailsBoldMobile from '../../assets/ProjectDetail_BoldMobile.jpg';
import projectDetailsMiracleMobile from '../../assets/ProjectDetail_MiracleMobile.jpg';
import projectDetailsWonderMobile from '../../assets/ProjectDetail_WonderMobile.jpg';
import projectDetailsJewelMobile from '../../assets/ProjectDetail_JewelMobile.jpg';
import projectDetailsDesignMobile from '../../assets/ProjectDetailsMobile.jpg';
import { useSelector } from 'react-redux';

export const useProjectDetails = () => {
  return useSelector((state) => {
    const { section2 } = state.projects;
    let projectDetails = '';

    if (section2 && section2.SectionData) {
      projectDetails = section2.SectionData.map(
        ({
          ProjectTitle_Section2,
          ProjectShortLocation_Section2,
          ProjectLocation_Section2,
          ProjectShortDuration_Section2,
          ProjectDuration_Section2,
          ProjectBackgroundImage_Section2,
          ProjectBackgroundImageAltTag,
          ProjectMobileBgImage_Section2,
          ProjectMobileBgImage_Section2AltTag,
          ProjectWorkArea_Section2,
          ProjectDescription_Section2,
          ProjectImage1_Section2,
          ProjectImage1AltTag,
          ProjectImage2_Section2,
          ProjectImage2AltTag,
          ProjectFeaturedImage_Section2,
          ProjectFeaturedImageAltTag,
          VideoCoverImage_Section2,
          GalleryImages_Section2,
          YoutubeVideo_Section2,
          GalleryVideoAndImages_Section2,
        }) => ({
          id: ProjectTitle_Section2.toLowerCase().replace(/\s+/g, '-'),
          title: ProjectTitle_Section2.toUpperCase(),
          shortLocation: ProjectShortLocation_Section2,
          fullLocation: ProjectLocation_Section2,
          shortDate: ProjectShortDuration_Section2,
          fullDate: ProjectDuration_Section2,
          img: ProjectBackgroundImage_Section2,
          alt: ProjectBackgroundImageAltTag,
          imgM: ProjectMobileBgImage_Section2,
          altM: ProjectMobileBgImage_Section2AltTag,
          workArea: ProjectWorkArea_Section2,
          description: ProjectDescription_Section2,
          leftImage: ProjectImage1_Section2,
          leftAlt: ProjectImage1AltTag,
          rightImage: ProjectImage2_Section2,
          rightAlt: ProjectImage2AltTag,
          thumbImage: ProjectFeaturedImage_Section2,
          thumbAlt: ProjectFeaturedImageAltTag,
          videoImage: VideoCoverImage_Section2,
          gallery: GalleryImages_Section2,
          YouTubeLink: YoutubeVideo_Section2,
          videoLink: GalleryVideoAndImages_Section2,
        })
      );
    }
    return projectDetails;
  });

  // projectDetails = [
  //   {
  //     title: 'DOOR STEP',
  //     shortLocation: 'Dubai', // new field
  //     fullLocation: 'Dubai Silicon Oasis, Dubai',
  //     shortDate: 'Oct 2022 - Dec 2022', // new field
  //     fullDate: 'October 2022 - December 2022',
  //     img: projectDetailsDoorStep,
  //     imgM: projectDetailsDoorStepMobile, // new field
  //     workArea: '1234 meters squared',
  //     description: `Discover OutMazed<sup>&reg;</sup> Design's first commercial success in an iconic tower at Dubai's Freezone, with state-of-the-art workspaces, meeting areas, and luxurious facilities. Additionally, the workspace has been redefined with beautiful flooring, trendy bathrooms, and adequate soundproofing. This project demonstrates our dedication to quality in transforming spaces at the heart of Dubai and establishes a new benchmark for modern business workplaces.`,
  //     leftImage: DoorStepLeftImg,
  //     rightImage: DoorStepRightImg,
  //     thumbImage: FeatureDoorStep,
  //     videoImage: vidPlay, // new field
  //     gallery: [
  //       { AfterImage_Section2: GalleryDoorStep1 },
  //       { AfterImage_Section2: GalleryDoorStep2 },
  //       { AfterImage_Section2: GalleryDoorStep3 },
  //     ],
  //     // new field
  //     YouTubeLink:
  //       'https://www.youtube-nocookie.com/embed/02vI5HWD8FQ?si=35qKeL8qKmDtN6Qk&amp;controls=0&amp;start=6',
  //     videoLink: 'https://demo.isoping.com:7313/assets/video/DoorStep.mp4',
  //     // metaInfo: {
  //     //   title: `Door Step Interior Design Project: Iconic Workspace at Dubai's Freezone`,
  //     //   keyword:
  //     //     'Dubai Freezone, commercial success, iconic tower, state-of-the-art workspaces, meeting areas, luxurious facilities, beautiful flooring, trendy bathrooms, soundproofing, modern business workplaces, OutMazed Design',
  //     //   description: `Explore OutMazed Design's commercial success in Dubai's Freezone tower, featuring state-of-the-art workspaces and luxurious facilities. Redefine your workspace with beautiful flooring and trendy interiors.`,
  //     //   canonical:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/door-step',
  //     //   author: 'Outmazed Design',
  //     //   pagetopic:
  //     //     'Interior Design, Commercial Projects, Dubai Freezone, Workspace Design, Luxurious Facilities, Trendy Interiors, OutMazed Design',
  //     //   Copyright: 'Outmazed Design',
  //     //   topic:
  //     //     'Interior Design, Commercial Projects, Dubai Freezone, Workspace Design, Luxurious Facilities, Trendy Interiors, OutMazed Design',
  //     //   language: 'english',
  //     //   property: 'Outmazed Design',
  //     //   owner: 'https://www.outmazeddesign.com/',
  //     //   rating: 'General',
  //     //   googlebot: 'index, follow',
  //     //   yahooseeker: 'index, follow',
  //     //   msnbot: 'index, follow',
  //     //   replyto: 'info@outmazeddesign.com',
  //     //   allowsearch: 'yes',
  //     //   revisitafter: 'daily',
  //     //   distribution: 'global',
  //     //   expires: 'never',
  //     //   HandheldFriendly: 'true',
  //     //   revisitafter: 'everyday',
  //     //   robots: 'index, follow',
  //     //   YahooSeeker: 'index, follow',
  //     //   SearchEngines:
  //     //     'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
  //     //   fbogtype: 'website',
  //     //   fbogsitename: 'Outmazed Design',
  //     //   fbogurl: 'https://www.facebook.com/OutMazedDesign',
  //     //   fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   fbogtitle:
  //     //     'Iconic Workspace Design: Dubai Freezone Tower | OutMazed Design',
  //     //   fbogdesc: `Explore OutMazed Design's commercial success in Dubai's Freezone tower, featuring state-of-the-art workspaces and luxurious facilities. Redefine your workspace with beautiful flooring and trendy interiors.`,
  //     //   twogcard: 'summary_large_image',
  //     //   twogsitename: '@OutMazedDesign',
  //     //   twogcreator: '@OutMazedDesign',
  //     //   twogurl: 'https://twitter.com/OutMazedDesign',
  //     //   twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   twoglogourlalt: 'Outmazed Design Logo',
  //     //   twogtitle:
  //     //     'Iconic Workspace Design: Dubai Freezone Tower | OutMazed Design',
  //     //   twogdesc: `Explore OutMazed Design's commercial success in Dubai's Freezone tower, featuring state-of-the-art workspaces and luxurious facilities. Redefine your workspace with beautiful flooring and trendy interiors.`,
  //     //   dcogcreator: 'Outmazed Interiors Design',
  //     //   dcoglanguage: 'en',
  //     //   dcogsource:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/door-step',
  //     //   dcogtitle:
  //     //     'Iconic Workspace Design: Dubai Freezone Tower | OutMazed Design',
  //     //   dcogdescription: `Explore OutMazed Design's commercial success in Dubai's Freezone tower, featuring state-of-the-art workspaces and luxurious facilities. Redefine your workspace with beautiful flooring and trendy interiors.`,
  //     //   dcogkeywords:
  //     //     'Dubai Freezone, workspace design, commercial projects, luxurious facilities, trendy interiors, OutMazed Design',
  //     //   dcogsubject:
  //     //     'Interior Design, Commercial Projects, Dubai Freezone, Workspace Design, Luxurious Facilities, Trendy Interiors, OutMazed Design',
  //     //   insogtitle:
  //     //     'Iconic Workspace Design: Dubai Freezone Tower | OutMazed Design',
  //     //   insogdescription: `Explore OutMazed Design's commercial success in Dubai's Freezone tower, featuring state-of-the-art workspaces and luxurious facilities. Redefine your workspace with beautiful flooring and trendy interiors.`,
  //     //   insogimg: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   insogurl: 'https://www.instagram.com/outmazeddesign/',
  //     // },
  //   },
  //   {
  //     title: 'ALPHA',
  //     shortLocation: 'Dubai',
  //     fullLocation: 'Dubai Silicon Oasis, Dubai',
  //     shortDate: 'Nov 2022 - Dec 2022',
  //     fullDate: 'November 2022 - December 2022',
  //     img: projectDetailsAlpha,
  //     imgM: projectDetailsAlphaMobile,
  //     workArea: '1234 meters squared',
  //     description:
  //       'PROJECT ALPHA is a commercial residential project representing modern, comfortable living in Damac Hills 2. With modernized indoor areas that include cove lighting, gypsum ceilings, beautiful flooring, and a contemporary outdoor oasis with ambient lighting, stepping stones, and landscaping, this project redefines modern-day living. Wall cladding and fake grass are among the luxurious touches that beautify every area of the property. The installation of 360-degree CCTV cameras is sure to provide homeowners with security and peace of mind. Modernity combined with coziness and style is what makes this home a shining example of sophisticated living.',
  //     leftImage: AlphaLeftImg,
  //     rightImage: AlphaRightImg,
  //     thumbImage: FeatureAlpha,
  //     videoImage: alphaVideo,
  //     gallery: [
  //       { AfterImage_Section2: GalleryAlpha1 },
  //       { AfterImage_Section2: GalleryAlpha2 },
  //       { AfterImage_Section2: GalleryAlpha3 },
  //     ],
  //     YouTubeLink:
  //       'https://www.youtube-nocookie.com/embed/4Z8I9n7ipcA?si=35qKeL8qKmDtN6Qk&amp;controls=0&amp;start=6',
  //     videoLink: 'https://demo.isoping.com:7313/assets/video/Alpha.mp4',
  //     // metaInfo: {
  //     //   title: `Alpha - Interior Design Projects | Outmazed Design`,
  //     //   keyword: 'alpha, interior design, projects, Outmazed Design',
  //     //   description: `Explore our alpha interior design projects curated by Outmazed Design. Discover innovative and stylish solutions to elevate your space. Dive into our portfolio today!`,
  //     //   canonical:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/alpha',
  //     //   author: 'Outmazed Design',
  //     //   pagetopic: 'Interior Design Projects',
  //     //   Copyright: 'Outmazed Design',
  //     //   topic: 'Interior Design Projects',
  //     //   language: 'english',
  //     //   property: 'Outmazed Design',
  //     //   owner: 'https://www.outmazeddesign.com/',
  //     //   rating: 'General',
  //     //   googlebot: 'index, follow',
  //     //   yahooseeker: 'index, follow',
  //     //   msnbot: 'index, follow',
  //     //   replyto: 'info@outmazeddesign.com',
  //     //   allowsearch: 'yes',
  //     //   revisitafter: 'daily',
  //     //   distribution: 'global',
  //     //   expires: 'never',
  //     //   HandheldFriendly: 'true',
  //     //   revisitafter: 'everyday',
  //     //   robots: 'index, follow',
  //     //   YahooSeeker: 'index, follow',
  //     //   SearchEngines:
  //     //     'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
  //     //   fbogtype: 'website',
  //     //   fbogsitename: 'Outmazed Design',
  //     //   fbogurl: 'https://www.facebook.com/OutMazedDesign',
  //     //   fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   fbogtitle: 'Alpha - Interior Design Projects | Outmazed Design',
  //     //   fbogdesc: `Explore our alpha interior design projects curated by Outmazed Design. Discover innovative and stylish solutions to elevate your space. Dive into our portfolio today!`,
  //     //   twogcard: 'summary_large_image',
  //     //   twogsitename: '@OutMazedDesign',
  //     //   twogcreator: '@OutMazedDesign',
  //     //   twogurl: 'https://twitter.com/OutMazedDesign',
  //     //   twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   twoglogourlalt: 'Outmazed Design Logo',
  //     //   twogtitle: 'Alpha - Interior Design Projects | Outmazed Design ',
  //     //   twogdesc: `Explore our alpha interior design projects curated by Outmazed Design. Discover innovative and stylish solutions to elevate your space. Dive into our portfolio today!`,
  //     //   dcogcreator: 'Outmazed Interiors Design',
  //     //   dcoglanguage: 'en',
  //     //   dcogsource:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/alpha',
  //     //   dcogtitle: 'Alpha - Interior Design Projects | Outmazed Design',
  //     //   dcogdescription: `Explore our alpha interior design projects curated by Outmazed Design. Discover innovative and stylish solutions to elevate your space. Dive into our portfolio today!`,
  //     //   dcogkeywords: 'alpha, interior design, projects, Outmazed Design',
  //     //   dcogsubject: 'Interior Design Projects',
  //     //   insogtitle: 'Alpha - Interior Design Projects | Outmazed Design',
  //     //   insogdescription: `Explore our alpha interior design projects curated by Outmazed Design. Discover innovative and stylish solutions to elevate your space. Dive into our portfolio today!`,
  //     //   insogimg: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   insogurl: 'https://www.instagram.com/outmazeddesign/',
  //     // },
  //   },
  //   {
  //     title: 'BOLD',
  //     shortLocation: 'Dubai',
  //     fullLocation: 'Dubai Silicon Oasis, Dubai',
  //     shortDate: 'Aug 2022 - Sep 2022',
  //     fullDate: 'August 2022 - September 2022',
  //     img: projectDetailsBold,
  //     imgM: projectDetailsBoldMobile,
  //     workArea: '1234 meters squared',
  //     description:
  //       'Located in Damac Hills 2, PROJECT BOLD is one of our unique commercial residential projects in Dubai. The chic wallpaper, ceiling painting, and interior carpeting all showcase our focus on modern aesthetics. The project includes well-planned outside lighting, wall cladding, an outdoor backsplash, and thoughtfully placed stepping stones that are all lit up by lovely outdoor lights. The landscape is enhanced with plant spike lights and artificial grass, creating an ideal balance of modern design and comfort.',
  //     leftImage: BoldLeftImg,
  //     rightImage: BoldRightImg,
  //     thumbImage: FeatureBold,
  //     videoImage: boldVideo,
  //     gallery: [
  //       { AfterImage_Section2: GalleryBold1 },
  //       { AfterImage_Section2: GalleryBold2 },
  //       { AfterImage_Section2: GalleryBold3 },
  //     ],
  //     YouTubeLink:
  //       'https://www.youtube-nocookie.com/embed/Ik_6V2UJUYk?si=35qKeL8qKmDtN6Qk&amp;controls=0&amp;start=7',
  //     videoLink: 'https://demo.isoping.com:7313/assets/video/Bold.mp4',
  //     // metaInfo: {
  //     //   title: `Bold Interior Design: Modern Living in Damac Hills 2 | Outmazed Design`,
  //     //   keyword:
  //     //     'Damac Hills 2, modern interior design, comfortable living, contemporary outdoor oasis, cove lighting, gypsum ceilings, wall cladding, fake grass, CCTV cameras, sophisticated living, Outmazed Design',
  //     //   description: `Discover modern comfort & sophistication in Damac Hills 2 with PROJECT ALPHA by Outmazed Design. Luxurious touches & CCTV security redefine living.`,
  //     //   canonical:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/bold',
  //     //   author: 'Outmazed Design',
  //     //   pagetopic:
  //     //     'Interior Design, Residential Projects, Damac Hills 2, Contemporary Living, Modern Comfort, Luxurious Touches, CCTV Security, Outmazed Design',
  //     //   Copyright: 'Outmazed Design',
  //     //   topic:
  //     //     'Interior Design, Residential Projects, Damac Hills 2, Contemporary Living, Modern Comfort, Luxurious Touches, CCTV Security, Outmazed Design',
  //     //   language: 'english',
  //     //   property: 'Outmazed Design',
  //     //   owner: 'https://www.outmazeddesign.com/',
  //     //   rating: 'General',
  //     //   googlebot: 'index, follow',
  //     //   yahooseeker: 'index, follow',
  //     //   msnbot: 'index, follow',
  //     //   replyto: 'info@outmazeddesign.com',
  //     //   allowsearch: 'yes',
  //     //   revisitafter: 'daily',
  //     //   distribution: 'global',
  //     //   expires: 'never',
  //     //   HandheldFriendly: 'true',
  //     //   revisitafter: 'everyday',
  //     //   robots: 'index, follow',
  //     //   YahooSeeker: 'index, follow',
  //     //   SearchEngines:
  //     //     'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
  //     //   fbogtype: 'website',
  //     //   fbogsitename: 'Outmazed Design',
  //     //   fbogurl: 'https://www.facebook.com/OutMazedDesign',
  //     //   fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   fbogtitle:
  //     //     'Bold Interior Design: Modern Living in Damac Hills 2 | Outmazed Design',
  //     //   fbogdesc: `Discover modern comfort & sophistication in Damac Hills 2 with PROJECT ALPHA by Outmazed Design. Luxurious touches & CCTV security redefine living.`,
  //     //   twogcard: 'summary_large_image',
  //     //   twogsitename: '@OutMazedDesign',
  //     //   twogcreator: '@OutMazedDesign',
  //     //   twogurl: 'https://twitter.com/OutMazedDesign',
  //     //   twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   twoglogourlalt: 'Outmazed Design Logo',
  //     //   twogtitle:
  //     //     'Bold Interior Design: Modern Living in Damac Hills 2 | Outmazed Design',
  //     //   twogdesc: `Discover modern comfort & sophistication in Damac Hills 2 with PROJECT ALPHA by Outmazed Design. Luxurious touches & CCTV security redefine living`,
  //     //   dcogcreator: 'Outmazed Interiors Design',
  //     //   dcoglanguage: 'en',
  //     //   dcogsource:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/bold',
  //     //   dcogtitle:
  //     //     'Bold Interior Design: Modern Living in Damac Hills 2 | Outmazed Design',
  //     //   dcogdescription: `Discover modern comfort & sophistication in Damac Hills 2 with PROJECT ALPHA by Outmazed Design. Luxurious touches & CCTV security redefine living.`,
  //     //   dcogkeywords:
  //     //     'Interior Design, Damac Hills 2, Modern Living, Comfort, Sophistication, Luxurious Touches, CCTV Security, Outmazed Design',
  //     //   dcogsubject:
  //     //     'Interior Design, Residential Projects, Damac Hills 2, Modern Living, Comfort, Sophistication, Luxurious Touches, CCTV Security, Outmazed Design',
  //     //   insogtitle:
  //     //     'Bold Interior Design: Modern Living in Damac Hills 2 | Outmazed Design',
  //     //   insogdescription: `Discover modern comfort & sophistication in Damac Hills 2 with PROJECT ALPHA by Outmazed Design. Luxurious touches & CCTV security redefine living.`,
  //     //   insogimg: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   insogurl: 'https://www.instagram.com/outmazeddesign/',
  //     // },
  //   },
  //   {
  //     title: 'MIRACLE',
  //     shortLocation: 'Dubai',
  //     fullLocation: 'Dubai Silicon Oasis, Dubai',
  //     shortDate: 'DEC 2021 - May 2022',
  //     fullDate: 'December 2022 - May 2022',
  //     img: projectDetailsMiracle,
  //     imgM: projectDetailsMiracleMobile,
  //     workArea: '1234 meters squared',
  //     description:
  //       'This project is an award-winning residential masterpiece in Dubai, and the biggest project by OutMazed<sup>&reg;</sup> Design features a full 360-degree makeover both indoors and outdoors. This project, which was featured on STARPLAYZ, includes joinery work, a new kitchen, lighting, furnishings, tiling, wall cladding, and bathroom improvements. It also includes gypsum ceilings with cove lights. The house has a redesigned boundary wall, 360-degree CCTV camera coverage, SMART home features, and a car shade. Enjoy the luxury of a gazebo, pergola, and beautifully designed outdoor space. A 9 x 4 skimmer swimming pool with a glass corner overlooks the seating area, which is enhanced with date palms, water pump relocation, and efficient spray painting. PROJECT MIRACLE is the perfect example of our ongoing commitment to creating living spaces that go above and beyond the norm, creating a world where exceptional comfort and elegance coexist.',
  //     leftImage: MiracleLeftImg,
  //     rightImage: MiracleRightImg,
  //     thumbImage: FeatureMiracle,
  //     videoImage: miracleVideo,
  //     gallery: [
  //       { AfterImage_Section2: GalleryMiracle1 },
  //       { AfterImage_Section2: GalleryMiracle2 },
  //       { AfterImage_Section2: GalleryMiracle3 },
  //     ],
  //     YouTubeLink:
  //       'https://www.youtube-nocookie.com/embed/hhNL_kfVLjk?si=35qKeL8qKmDtN6Qk&amp;controls=0&amp;start=6',
  //     videoLink: 'https://demo.isoping.com:7313/assets/video/Miracle.mp4',
  //     // metaInfo: {
  //     //   title: `Project Miracle: Award-Winning Residential Masterpiece | OutMazed® Design`,
  //     //   keyword:
  //     //     'Project Miracle, residential masterpiece, Dubai, OutMazed Design, interior design, renovation, SMART home, luxury living',
  //     //   description: `Explore Project Miracle, an award-winning residential masterpiece by OutMazed® Design in Dubai. Features a full 360-degree makeover, SMART home, and luxurious outdoor spaces`,
  //     //   canonical:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/miracle',
  //     //   author: 'OutMazed® Design Team',
  //     //   pagetopic: 'Residential Interior Design Project',
  //     //   Copyright: 'Outmazed Design',
  //     //   topic: 'Residential Interior Design Project',
  //     //   language: 'english',
  //     //   property: 'OutMazed® Design',
  //     //   owner: 'https://www.outmazeddesign.com/',
  //     //   rating: 'General',
  //     //   googlebot: 'index, follow',
  //     //   yahooseeker: 'index, follow',
  //     //   msnbot: 'index, follow',
  //     //   replyto: 'info@outmazeddesign.com',
  //     //   allowsearch: 'yes',
  //     //   revisitafter: 'daily',
  //     //   distribution: 'global',
  //     //   expires: 'never',
  //     //   HandheldFriendly: 'true',
  //     //   revisitafter: 'everyday',
  //     //   robots: 'index, follow',
  //     //   YahooSeeker: 'index, follow',
  //     //   SearchEngines:
  //     //     'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
  //     //   fbogtype: 'website',
  //     //   fbogsitename: 'Outmazed Design',
  //     //   fbogurl: 'https://www.facebook.com/OutMazedDesign',
  //     //   fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   fbogtitle:
  //     //     'Project Miracle: Award-Winning Residential Masterpiece | OutMazed® Design',
  //     //   fbogdesc: `Explore Project Miracle, an award-winning residential masterpiece by OutMazed® Design in Dubai. Features a full 360-degree makeover, SMART home, and luxurious outdoor spaces.`,
  //     //   twogcard: 'summary_large_image',
  //     //   twogsitename: '@OutMazedDesign',
  //     //   twogcreator: '@OutMazedDesign',
  //     //   twogurl: 'https://twitter.com/OutMazedDesign',
  //     //   twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   twoglogourlalt: 'Outmazed Design Logo',
  //     //   twogtitle:
  //     //     'Project Miracle: Award-Winning Residential Masterpiece | OutMazed® Design',
  //     //   twogdesc: `Explore Project Miracle, an award-winning residential masterpiece by OutMazed® Design in Dubai. Features a full 360-degree makeover, SMART home, and luxurious outdoor spaces.`,
  //     //   dcogcreator: 'Outmazed Interiors Design',
  //     //   dcoglanguage: 'en',
  //     //   dcogsource:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/miracle',
  //     //   dcogtitle:
  //     //     'Project Miracle: Award-Winning Residential Masterpiece | OutMazed® Design',
  //     //   dcogdescription: `Explore Project Miracle, an award-winning residential masterpiece by OutMazed® Design in Dubai. Features a full 360-degree makeover, SMART home, and luxurious outdoor spaces.`,
  //     //   dcogkeywords:
  //     //     'Project Miracle, residential masterpiece, Dubai, OutMazed Design, interior design, renovation, SMART home, luxury living',
  //     //   dcogsubject: 'Residential Interior Design Project',
  //     //   insogtitle:
  //     //     'Project Miracle: Award-Winning Residential Masterpiece | OutMazed® Design',
  //     //   insogdescription: `Explore Project Miracle, an award-winning residential masterpiece by OutMazed® Design in Dubai. Features a full 360-degree makeover, SMART home, and luxurious outdoor spaces.`,
  //     //   insogimg: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   insogurl: 'https://www.instagram.com/outmazeddesign/',
  //     // },
  //   },
  //   {
  //     title: 'WONDER',
  //     shortLocation: 'Dubai',
  //     fullLocation: 'Dubai Silicon Oasis, Dubai',
  //     shortDate: 'May 2021 - Jun 2021',
  //     fullDate: 'May 2021 - June 2021',
  //     img: projectDetailsWonder,
  //     imgM: projectDetailsWonderMobile,
  //     workArea: '1234 meters squared',
  //     description:
  //       'PROJECT WONDER offers a fresh perspective on modern living, from improved kitchens to modernized bathrooms and balconies that seamlessly combine beauty and functionality. Artificial glass and bespoke woodwork cabinets provide efficient storage options, bringing modern elegance to the forefront. Step into outdoor areas transformed by expansive balcony landscaping, creating a functional and luxurious living experience.',
  //     leftImage: WonderLeftImg,
  //     rightImage: WonderRightImg,
  //     thumbImage: FeatureWonder,
  //     videoImage: wonderVideo,
  //     gallery: [
  //       { AfterImage_Section2: GalleryWonder1 },
  //       { AfterImage_Section2: GalleryWonder2 },
  //       { AfterImage_Section2: GalleryWonder3 },
  //     ],
  //     YouTubeLink:
  //       'https://www.youtube-nocookie.com/embed/hKhAUWzb9nw?si=35qKeL8qKmDtN6Qk&amp;controls=0&amp;start=7',
  //     videoLink: 'https://demo.isoping.com:7313/assets/video/Wonder.mp4',
  //     // metaInfo: {
  //     //   title: `Project Wonder: Modern Living | Outmazed Design`,
  //     //   keyword:
  //     //     'wonder project, modern living, kitchen improvements, bathroom renovations, balcony landscaping, artificial glass, bespoke woodwork, efficient storage, luxurious living',
  //     //   description: `Explore Project Wonder by Outmazed Design, offering a fresh perspective on modern living. Discover luxurious living experiences`,
  //     //   canonical:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/wonder',
  //     //   author: 'Outmazed Design',
  //     //   pagetopic: 'Modern Living Spaces',
  //     //   Copyright: 'Outmazed Design',
  //     //   topic: 'Modern Living Spaces',
  //     //   language: 'english',
  //     //   property: 'OutMazed® Design',
  //     //   owner: 'https://www.outmazeddesign.com/',
  //     //   rating: 'General',
  //     //   googlebot: 'index, follow',
  //     //   yahooseeker: 'index, follow',
  //     //   msnbot: 'index, follow',
  //     //   replyto: 'info@outmazeddesign.com',
  //     //   allowsearch: 'yes',
  //     //   revisitafter: 'daily',
  //     //   distribution: 'global',
  //     //   expires: 'never',
  //     //   HandheldFriendly: 'true',
  //     //   revisitafter: 'everyday',
  //     //   robots: 'index, follow',
  //     //   YahooSeeker: 'index, follow',
  //     //   SearchEngines:
  //     //     'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
  //     //   fbogtype: 'website',
  //     //   fbogsitename: 'Outmazed Design',
  //     //   fbogurl: 'https://www.facebook.com/OutMazedDesign',
  //     //   fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   fbogtitle: 'Project Wonder: Modern Living | Outmazed Design',
  //     //   fbogdesc: `Explore Project Wonder by Outmazed Design, offering a fresh perspective on modern living. Discover luxurious living experiences.`,
  //     //   twogcard: 'summary_large_image',
  //     //   twogsitename: '@OutMazedDesign',
  //     //   twogcreator: '@OutMazedDesign',
  //     //   twogurl: 'https://twitter.com/OutMazedDesign',
  //     //   twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   twoglogourlalt: 'Outmazed Design Logo',
  //     //   twogtitle: 'Project Wonder: Modern Living | Outmazed Design',
  //     //   twogdesc: `Explore Project Wonder by Outmazed Design, offering a fresh perspective on modern living. Discover luxurious living experiences.`,
  //     //   dcogcreator: 'Outmazed Interiors Design',
  //     //   dcoglanguage: 'en',
  //     //   dcogsource:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/wonder',
  //     //   dcogtitle: 'Project Wonder: Modern Living | Outmazed Design',
  //     //   dcogdescription: `Explore Project Wonder by Outmazed Design, offering a fresh perspective on modern living. Discover luxurious living experiences.`,
  //     //   dcogkeywords:
  //     //     'Project Wonder, modern living, Outmazed Design, kitchen improvements, bathroom renovations, balcony landscaping, luxurious living',
  //     //   dcogsubject: 'Modern Living Spaces',
  //     //   insogtitle: 'Project Wonder: Modern Living | Outmazed Design',
  //     //   insogdescription: `Explore Project Wonder by Outmazed Design, offering a fresh perspective on modern living. Discover luxurious living experiences.`,
  //     //   insogimg: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   insogurl: 'https://www.instagram.com/outmazeddesign/',
  //     // },
  //   },
  //   {
  //     title: 'JEWEL',
  //     shortLocation: 'Dubai',
  //     fullLocation: 'Dubai Silicon Oasis, Dubai',
  //     shortDate: 'Feb 2022 - Jul 2020',
  //     fullDate: 'February 2020 - July 2020',
  //     img: projectDetailsJewel,
  //     imgM: projectDetailsJewelMobile,
  //     workArea: '1234 meters squared',
  //     description:
  //       'This unique project brings a transformative touch in a prestigious Dubai villa community.  The living experience has been enhanced with a new kitchen, eye-catching lighting, chic furniture, and remodeled bathrooms. Modern features like glasswork, wood flooring, and SMART home elements improve the beauty and security of the space. Outdoors has a 3.5 x 7 skimmer swimming pool, covered parking, beautiful landscaping, and refurbished doors and closets.',
  //     leftImage: JewelLeftImg,
  //     rightImage: JewelRightImg,
  //     thumbImage: FeatureJewel,
  //     videoImage: jewelVideo,
  //     gallery: [
  //       { AfterImage_Section2: GalleryJewel1 },
  //       { AfterImage_Section2: GalleryJewel2 },
  //       { AfterImage_Section2: GalleryJewel3 },
  //     ],
  //     YouTubeLink:
  //       'https://www.youtube-nocookie.com/embed/RwcU42iG9cg?si=35qKeL8qKmDtN6Qk&amp;controls=0&amp;start=7',
  //     videoLink: 'https://demo.isoping.com:7313/assets/video/Jewel.mp4',
  //     // metaInfo: {
  //     //   title: `Project Jewel: Transformative Touch in Dubai | Outmazed Design`,
  //     //   keyword:
  //     //     'jewel project, Dubai villa, transformative touch, new kitchen, eye-catching lighting, chic furniture, remodeled bathrooms, glasswork, wood flooring, SMART home, skimmer swimming pool, covered parking, landscaping, refurbished doors, closets',
  //     //   description: `Discover Project Jewel by Outmazed Design, bringing a transformative touch to a prestigious Dubai villa community. Enhance your living experience.`,
  //     //   canonical:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/jewel',
  //     //   author: 'Outmazed Design',
  //     //   pagetopic: 'Dubai Villa Community Transformation',
  //     //   Copyright: 'Outmazed Design',
  //     //   topic: 'Dubai Villa Community Transformation',
  //     //   language: 'english',
  //     //   property: 'OutMazed® Design',
  //     //   owner: 'https://www.outmazeddesign.com/',
  //     //   rating: 'General',
  //     //   googlebot: 'index, follow',
  //     //   yahooseeker: 'index, follow',
  //     //   msnbot: 'index, follow',
  //     //   replyto: 'info@outmazeddesign.com',
  //     //   allowsearch: 'yes',
  //     //   revisitafter: 'daily',
  //     //   distribution: 'global',
  //     //   expires: 'never',
  //     //   HandheldFriendly: 'true',
  //     //   revisitafter: 'everyday',
  //     //   robots: 'index, follow',
  //     //   YahooSeeker: 'index, follow',
  //     //   SearchEngines:
  //     //     'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
  //     //   fbogtype: 'website',
  //     //   fbogsitename: 'Outmazed Design',
  //     //   fbogurl: 'https://www.facebook.com/OutMazedDesign',
  //     //   fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   fbogtitle:
  //     //     'Project Jewel: Transformative Touch in Dubai | Outmazed Design',
  //     //   fbogdesc: `Discover Project Jewel by Outmazed Design, bringing a transformative touch to a prestigious Dubai villa community. Enhance your living experience.`,
  //     //   twogcard: 'summary_large_image',
  //     //   twogsitename: '@OutMazedDesign',
  //     //   twogcreator: '@OutMazedDesign',
  //     //   twogurl: 'https://twitter.com/OutMazedDesign',
  //     //   twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   twoglogourlalt: 'Outmazed Design Logo',
  //     //   twogtitle:
  //     //     'Project Jewel: Transformative Touch in Dubai | Outmazed Design',
  //     //   twogdesc: `Discover Project Jewel by Outmazed Design, bringing a transformative touch to a prestigious Dubai villa community. Enhance your living experience.`,
  //     //   dcogcreator: 'Outmazed Interiors Design',
  //     //   dcoglanguage: 'en',
  //     //   dcogsource:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/jewel',
  //     //   dcogtitle:
  //     //     'Project Jewel: Transformative Touch in Dubai | Outmazed Design',
  //     //   dcogdescription: `Discover Project Jewel by Outmazed Design, bringing a transformative touch to a prestigious Dubai villa community. Enhance your living experience.`,
  //     //   dcogkeywords:
  //     //     'Project Jewel, Dubai villa, transformative touch, new kitchen, lighting, chic furniture, remodeled bathrooms, glasswork, wood flooring, SMART home, swimming pool, covered parking, landscaping, doors, closets',
  //     //   dcogsubject: 'Dubai Villa Community Transformation',
  //     //   insogtitle:
  //     //     'Project Jewel: Transformative Touch in Dubai | Outmazed Design',
  //     //   insogdescription: `Discover Project Jewel by Outmazed Design, bringing a transformative touch to a prestigious Dubai villa community. Enhance your living experience.`,
  //     //   insogimg: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   insogurl: 'https://www.instagram.com/outmazeddesign/',
  //     // },
  //   },
  //   {
  //     title: 'DESIGN',
  //     shortLocation: 'Dubai',
  //     fullLocation: 'Dubai Silicon Oasis, Dubai',
  //     shortDate: 'Feb 2022 - Jul 2020',
  //     fullDate: 'February 2020 - July 2020',
  //     img: projectDetailsDesign,
  //     imgM: projectDetailsDesignMobile,
  //     workArea: '1234 meters squared',
  //     description:
  //       'This is a commercial space project located in Dubai aimed at transforming spaces into futuristic havens. The open-ceiling architecture and sophisticated frosted glass offices provide a spacious atmosphere. Additional improvements include kitchen wrapping, carpeting, flooring, and design painting, creating a beautiful balance of beauty and functionality. The project also focuses on security by installing CCTV cameras in the office space. Get yourself a workspace where innovation meets design excellence.',
  //     leftImage: DesignLeftImg,
  //     rightImage: DesignRightImg,
  //     thumbImage: FeatureDesign,
  //     videoImage: designVideo,
  //     gallery: [
  //       { AfterImage_Section2: GalleryDesign1 },
  //       { AfterImage_Section2: GalleryDesign2 },
  //       { AfterImage_Section2: GalleryDesign3 },
  //     ],
  //     YouTubeLink:
  //       'https://www.youtube-nocookie.com/embed/ZDdKJ1GSAJs?si=35qKeL8qKmDtN6Qk&amp;controls=0&amp;start=7',
  //     videoLink: 'https://demo.isoping.com:7313/assets/video/Design.mp4',
  //     // metaInfo: {
  //     //   title: `Futuristic Commercial Space Project | Outmazed Design`,
  //     //   keyword:
  //     //     'commercial space project, Dubai, futuristic, open-ceiling architecture, frosted glass offices, kitchen wrapping, carpeting, flooring, design painting, CCTV cameras, innovation, design excellence',
  //     //   description: `Discover this commercial space project by Outmazed Design in Dubai, transforming spaces into futuristic havens. Elevate your workspace!`,
  //     //   canonical:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/design',
  //     //   author: 'Outmazed Design',
  //     //   pagetopic: 'Futuristic Commercial Space Transformation',
  //     //   Copyright: 'Outmazed Design',
  //     //   topic: 'Futuristic Commercial Space Transformation',
  //     //   language: 'english',
  //     //   property: 'OutMazed® Design',
  //     //   owner: 'https://www.outmazeddesign.com/',
  //     //   rating: 'General',
  //     //   googlebot: 'index, follow',
  //     //   yahooseeker: 'index, follow',
  //     //   msnbot: 'index, follow',
  //     //   replyto: 'info@outmazeddesign.com',
  //     //   allowsearch: 'yes',
  //     //   revisitafter: 'daily',
  //     //   distribution: 'global',
  //     //   expires: 'never',
  //     //   HandheldFriendly: 'true',
  //     //   revisitafter: 'everyday',
  //     //   robots: 'index, follow',
  //     //   YahooSeeker: 'index, follow',
  //     //   SearchEngines:
  //     //     'Google, Bing, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, DogPile, Ask, WebCrawler',
  //     //   fbogtype: 'website',
  //     //   fbogsitename: 'Outmazed Design',
  //     //   fbogurl: 'https://www.facebook.com/OutMazedDesign',
  //     //   fboglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   fbogtitle: 'Futuristic Commercial Space Project | Outmazed Design',
  //     //   fbogdesc: `Explore this commercial space project by Outmazed Design in Dubai, transforming spaces into futuristic havens. Elevate your workspace!`,
  //     //   twogcard: 'summary_large_image',
  //     //   twogsitename: '@OutMazedDesign',
  //     //   twogcreator: '@OutMazedDesign',
  //     //   twogurl: 'https://twitter.com/OutMazedDesign',
  //     //   twoglogourl: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   twoglogourlalt: 'Outmazed Design Logo',
  //     //   twogtitle: 'Futuristic Commercial Space Project | Outmazed Design',
  //     //   twogdesc: `Discover this commercial space project by Outmazed Design in Dubai, transforming spaces into futuristic havens. Elevate your workspace!`,
  //     //   dcogcreator: 'Outmazed Interiors Design',
  //     //   dcoglanguage: 'en',
  //     //   dcogsource:
  //     //     'https://www.outmazeddesign.com/interior-design-projects/design',
  //     //   dcogtitle: 'Futuristic Commercial Space Project | Outmazed Design',
  //     //   dcogdescription: `Explore this commercial space project by Outmazed Design in Dubai, transforming spaces into futuristic havens. Elevate your workspace!`,
  //     //   dcogkeywords:
  //     //     'commercial space project, Dubai, futuristic, open-ceiling architecture, frosted glass offices, kitchen wrapping, carpeting, flooring, design painting, CCTV cameras, innovation, design excellence',
  //     //   dcogsubject: 'Futuristic Commercial Space Transformation',
  //     //   insogtitle: 'Futuristic Commercial Space Project | Outmazed Design',
  //     //   insogdescription: `Explore this commercial space project by Outmazed Design in Dubai, transforming spaces into futuristic havens. Elevate your workspace!`,
  //     //   insogimg: 'https://www.outmazeddesign.com/images/logo.png',
  //     //   insogurl: 'https://www.instagram.com/outmazeddesign/',
  //     // },
  //   },
  // ];
};
