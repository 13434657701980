import { createSlice } from '@reduxjs/toolkit';

// Initial state for About Us slice
const initialState = {
  section1: {},
};

const aboutUsSlice = createSlice({
  name: 'aboutUs',
  initialState,
  reducers: {
    setAboutUsSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const aboutUsActions = aboutUsSlice.actions;
export const aboutUsReducer = aboutUsSlice.reducer;

export const AboutUsActionTypes = {
  FETCH_ABOUT_US: 'AboutUs/Fetch',
};
