import { Link } from 'react-router-dom';
import { BsTelephoneFill, BsEnvelopeFill } from 'react-icons/bs';
import parse from 'html-react-parser';

const Body = ({ section1 }) => {
  const description = section1.Description_Section1;
  let parsedString = '';

  if (description) {
    parsedString = parse(description.replace(/\n/g, ''));
  }

  return (
    <div className='GlobalContainerNonBg'>
      <div className='container zIndex2'>
        <div className='row align-items-center'>
          <div className='col-md-12'>
            <div className='PrivacyPolicyText'>{parsedString}</div>
            {/* <div className='PrivacyPolicyText'>
              <b className='WhiteColor'>Effective Date: February 02, 2024</b>
              <ol>
                <li>
                  <h2>Acceptance of Terms</h2>
                  <p>
                    By engaging with OutMazed<sup>&reg;</sup> Design's ("we,"
                    "our," or "us") services, you accept and agree to these
                    terms and conditions.
                  </p>
                </li>
                <li>
                  <h2>Specialized Services</h2>
                  <p>
                    OutMazed<sup>&reg;</sup> Design specializes in offering
                    interior design and fit-out services, subject to the terms
                    specified in individual service agreements.
                  </p>
                </li>
                <li>
                  <h2>User Responsibilities</h2>
                  <p>
                    Throughout the project, you must supply correct information,
                    cooperate with our team, and adhere to all applicable rules
                    and regulations.
                  </p>
                </li>
                <li>
                  <h2>Pricing & Payment</h2>
                  <p>
                    Individual service agreements provide specific pricing and
                    payment methods. Clients must make the agreed-upon payments
                    as provided in the agreement.
                  </p>
                </li>
                <li>
                  <h2>Cancellation and Refund Procedures</h2>
                  <p>
                    Individual service agreements will provide clearly stated
                    cancellation and refund procedures.
                  </p>
                </li>
                <li>
                  <h2>Intellectual Property Ownership</h2>
                  <p>
                    OutMazed<sup>&reg;</sup> Design retains sole ownership of
                    all intellectual property related to our services, including
                    drawings, plans, and specifications. Unless otherwise
                    specified in the service agreement.
                  </p>
                </li>
                <li>
                  <h2>Privacy Compliance</h2>
                  <p>
                    We gather and process personal information in accordance
                    with the principles set out in our Privacy Policy. By using
                    our services, you agree to our policies on the collection
                    and use of your data.
                  </p>
                </li>
                <li>
                  <h2>Liability Disclaimers</h2>
                  <p>
                    By engaging with OutMazed<sup>&reg;</sup> Design disclaims
                    all liability for any indirect, special, or consequential
                    damages and makes no promises, expressed or implied, with
                    regard to the services.
                  </p>
                </li>
                <li>
                  <h2>Termination Rights</h2>
                  <p>
                    Either party has the right to cancel a service agreement in
                    line with the termination terms stated in the agreement.
                  </p>
                </li>
                <li>
                  <h2>Governing Legal Framework</h2>
                  <p>
                    These Terms & Conditions are regulated by and construed in
                    line with United Arab Emirates laws.
                  </p>
                </li>
                <li>
                  <h2>Updates in Terms</h2>
                  <p>
                    Design has the right to alter these Terms and Conditions
                    from time to time. Any changes take effect as soon as they
                    are posted, therefore we advise you to revisit these
                    conditions regularly.
                  </p>
                </li>
                <li>
                  <h2>Contact Us</h2>
                  <p>
                    For any questions or feedback about our Terms & Conditions,
                    please contact us at:
                    <p>
                      OutMazed<sup>&reg;</sup> Design
                      <br />
                      SIT Tower, Office 2012, 20th Floor, Dubai Silicon Oasis,
                      Dubai, United Arab Emirates
                      <br />
                      <Link to='tel://+97143399683'>
                        <BsTelephoneFill />
                        &nbsp;&nbsp;+971 4 339 9683
                      </Link>
                      <br />
                      <Link to='mailto:info@outmazeddesign.com'>
                        <BsEnvelopeFill /> &nbsp;&nbsp;info@outmazeddesign.com
                      </Link>
                    </p>
                  </p>
                </li>
              </ol>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
