import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import contactUsService from '../services/ContactUsService';
import {
  ContactUsActionTypes,
  contactUsActions,
} from '../store/slices/contactUsSlice';

// saga handler to fetch ContactUs sectionwise and update redux store as per the api payload
export const fetchContactUsSection = function* (action) {
  try {
    const response = yield call(
      contactUsService.getContactUsSection,
      action.payload
    );
    const responseData = yield get(response, 'data');
    yield put(
      contactUsActions.setContactUsSection({
        sectionName: 'SectionData',
        data: responseData.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* contactUsSaga() {
  yield takeLatest(
    ContactUsActionTypes.FETCH_CONTACT_US,
    fetchContactUsSection
  );
}
