import { createSlice } from '@reduxjs/toolkit';

// Initial state for themePage slice
const initialState = {
  section1: {},
};


const themePageSlice = createSlice({
    name: 'themePage',
    initialState,
    reducers: {
      setThemePageSection: (state, action) => {
        const { payload } = action;
        state[payload.sectionName] = payload.data;
      },
    },
  });


export const themePageActions = themePageSlice.actions;
export const themePageReducer = themePageSlice.reducer;

export const ThemePageActionTypes = {
    FETCH_THEME_PAGE: 'ThemePage/Fetch',
  };