// file for utility functions

/**
 * To check input string is valid JSON or not
 *
 * @param {string} input -input to check is valid JSON or not
 * @returns boolean - true, if valid else false
 */
export const isJson = (input) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};
