import React, { useEffect } from 'react';
import Header from '../../components/PrivacyPolicy/Header/Header';
import Body from '../../components/PrivacyPolicy/Body/Body';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivacySection } from '../../actions/privacy';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { SectionData } = useSelector((state) => state.privacy);

  let section1 = '';
  if (SectionData) {
    section1 = SectionData[1][0];
  }

  useEffect(() => {
    dispatch(getPrivacySection({ sectionName: '' }));
  }, []);

  return (
    <>
      <Helmet>{SectionData && parse(SectionData[0][0]?.MetaHeaderTags)}</Helmet>
      <Header section1={section1} />
      <Body section1={section1} />
    </>
  );
};

export default PrivacyPolicy;
