import { ContactUsActionTypes } from '../store/slices/contactUsSlice';

/**
 * To get contactUs page data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getContactUsSection = (payload) => ({
  type: ContactUsActionTypes.FETCH_CONTACT_US,
  payload,
});
