import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import FeatureFlagsProvider from './providers/FeatureFlagsProvider';
import I18nProvider from './providers/I18nProvider';
import LanguageProvider from './providers/LanguageProvider';
import './wdyr';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FeatureFlagsProvider>
      <LanguageProvider>
        <I18nProvider>
          <App />
        </I18nProvider>
      </LanguageProvider>
    </FeatureFlagsProvider>
  </React.StrictMode>
);
