import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';
import store from './store/store';
import GlobalStyle from './styles/GlobalStyle';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './config/appConfig';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter basename='/'>
      <Provider store={store}>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          style={{ zIndex: 10000000 }}
        />
        <GlobalStyle />
        <Routes />
        <ScrollToTop />
      </Provider>
    </BrowserRouter>
  );
};

export default App;
