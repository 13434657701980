import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class ThemePageService {
  // POST request to fetch Theme Page page section wise
  async getThemePageSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'Themes',
      Section: payload.sectionName,
    });
    return response;
  }
}

const themePageService = new ThemePageService();
export default themePageService;
