import { PrivacyActionTypes } from '../store/slices/privacySlice';

/**
 * To get privacy page data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getPrivacySection = (payload) => ({
  type: PrivacyActionTypes.FETCH_PRIVACY,
  payload,
});
