import Outmazedlogo from '../../assets/footerLogo.svg';
import getFooterDetails, { socialLinks } from './FooterConfig';
import FooterMobile from './FooterMobile';
import { Link } from 'react-router-dom';
import { PAGE_URL } from '../../config/appConfig';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { BsEnvelope, BsEnvelopeFill, BsTelephoneFill } from 'react-icons/bs';
import { FaLocationDot } from 'react-icons/fa6';
import { capitalize } from 'lodash';

const Footer = () => {
  const { SectionData } = useSelector((state) => state.siteInfo);
  const [service, setService] = useState([]);
  const { SectionData: services } = useSelector((state) => state.services);

  useEffect(() => {
    if (services) {
      const newData = services[2].map(({ ServiceTitle2 }) => ({
        label: ServiceTitle2.split(' ')
          .map((word) => capitalize(word))
          .join(' '),
        href: `${PAGE_URL.SERVICE}#${ServiceTitle2.toLowerCase().replace(
          /\s+/g,
          '_'
        )}`,
      }));
      setService(newData);
    }
  }, [services]);

  // useEffect(() => {
  //   getFooterDetails.map;
  // }, [SectionData]);

  return (
    <>
      <div
        className='container-fluid GlobalContainer py-0'
        data-aos='fade-right'
      >
        <div className='row footerBg d-md-block d-none'>
          <div className='container'>
            <div className='row justify-content-evenly'>
              <div
                className='col-md-2 Fcol Fcol1 text-center'
                data-aos='fade-up'
              >
                <Link className='' to='/'>
                  <img
                    src={SectionData && SectionData[1][0]?.SiteFooterLogo}
                    width='132'
                    alt={SectionData ? SectionData[1][0]?.FooterLogoAltTag : ''}
                  />
                </Link>
              </div>
              <div
                className='col-md-3 Fcol get-in-touch-web'
                data-aos='fade-up'
              >
                <span>Get In Touch</span>
                <ul>
                  <li>
                    <a
                      href={`tel://${
                        SectionData && SectionData[1][0]?.SitePhoneNo
                      }`}
                    >
                      <BsTelephoneFill />
                      {SectionData && SectionData[1][0]?.SitePhoneNo}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:${
                        SectionData && SectionData[1][0]?.SiteEmail
                      }`}
                    >
                      <BsEnvelopeFill />
                      {SectionData && SectionData[1][0]?.SiteEmail}
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <FaLocationDot />
                      {SectionData && SectionData[1][0]?.SiteAddress}
                    </a>
                  </li>
                </ul>
                <div className='SocialMediaLink'>
                  {socialLinks?.map(
                    (socialItem, index) =>
                      SectionData &&
                      SectionData[1][0][socialItem.cmsUrl].length !== 0 && (
                        <a
                          target='_blank'
                          href={
                            SectionData && SectionData[1][0][socialItem.cmsUrl]
                          }
                          key={index}
                        >
                          {socialItem.icon}
                        </a>
                      )
                  )}
                </div>
              </div>
              {getFooterDetails().map((data, index) => (
                <div
                  className={`col-md-3 Fcol ${data?.class} ${data?.btnClass}`}
                  data-aos='fade-up'
                  key={index}
                >
                  {data.detailName === 'SERVICES' ? (
                    <>
                      <span> {data.detailName} </span>
                      <ul>
                        {service.map((detail, index) => (
                          <li key={index}>
                            <Link to={detail.href}>{detail.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span> {data.detailName} </span>
                      <ul>
                        {data.subHeading.map((detail, index) => (
                          <li key={index}>
                            <Link to={detail.href}>{detail.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='row Finfo d-md-block d-none'>
          <ul>
            <li>
              &copy; 2024 Outmazed<sup>&reg;</sup> Design. All Rights Reserved
            </li>
            <li>
              <Link to={PAGE_URL.TERMS_OF_USE}>Terms of Use</Link>
            </li>
            <li>
              <Link to={PAGE_URL.PRIVACY}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={PAGE_URL.FAQ}>FAQs</Link>
            </li>
          </ul>
        </div>
        <FooterMobile />
      </div>
    </>
  );
};

export default Footer;
