import { useCallback, useEffect, useMemo, useState } from 'react';
import Storage from '../helpers/Storage';
import { getDefaultLocale, getLocaleInfo } from '../utils/locale';

/**
 * useLocale hook: To switch preferred language.
 *
 * @namespace useLocale
 *
 * @returns [locale, setLocale] - locale value and setLocale function
 */
export const useLocale = () => {
  const currentLocale = useMemo(() => getDefaultLocale(), []);
  const [locale, changeLocale] = useState(currentLocale);

  const setLocale = useCallback(
    (locale) => {
      // if locale value is like 'hi-IN', 'ta-IN'
      if (typeof locale === 'string') {
        // get locale info as object  { locale, direction }
        locale = getLocaleInfo(locale);
      }
      // store in local storage
      Storage.setItem('locale', locale);
      changeLocale(locale);
    },
    [locale]
  );

  useEffect(() => {
    // Get from local storage
    const defaultLocale = Storage.getItem('locale');
    // if locale is not available in local storage, synch local storage
    if (!defaultLocale) {
      setLocale(currentLocale);
    }
  }, []);

  return [locale, setLocale];
};

export default useLocale;
