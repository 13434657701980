import React, { useContext } from 'react';

/**
 * I18nContext: to provide selected language name and translated message
 *
 * @namespace I18nContext
 */
export const I18nContext = React.createContext();

export const useI18nContext = () => useContext(I18nContext);
