import { useSelector } from 'react-redux';

const useDescriptionData = () => {
  return useSelector((state) => {
    const { SectionData } = state.careers;
    let jobData = [];

    if (SectionData && SectionData[2]) {
      jobData = SectionData[2].map(
        ({ BenefitTitle_Section2, BenefitDescription_Section2 }) => ({
          heading: BenefitTitle_Section2,
          detail: BenefitDescription_Section2,
        })
      );
    }

    return jobData;
  });
};

export default useDescriptionData;
