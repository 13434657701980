import { GET_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class FAQService {
  // POST request to fetch FAQ page section wise
  async getFAQSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: 'FAQ',
      Section: payload.sectionName,
    });
    return response;
  }
}

const faqService = new FAQService();
export default faqService;
