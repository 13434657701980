import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import faqService from '../services/FAQService';
import { FAQActionTypes, faqActions } from '../store/slices/faqSlice';

// saga handler to fetch FAQ sectionwise and update redux store as per the api payload
export const fetchFAQSection = function* (action) {
  try {
    const response = yield call(faqService.getFAQSection, action.payload);
    const responseData = yield get(response, 'data');
    yield put(
      faqActions.setFAQSection({
        sectionName: 'SectionData',
        data: responseData.SectionData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* faqSaga() {
  yield takeLatest(FAQActionTypes.FETCH_FAQ, fetchFAQSection);
}
